<template>
  <div class="entity-title">
    <h4 :class="dark ? 'entity-title-dark' : 'entity-title-light'">
      {{ entity.name }}
    </h4>
    <span class="caption">
      {{ entity.stock_ticker }}
      <span
        v-if="entity.stock_ticker"
        class="country-flag mx-1"
        :class="countryFlag"
      ></span>
      {{ entity.stock_exchange }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'EntityTitle',
  props: {
    entity: {
      type: Object,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    countryFlag() {
      if (!this.entity.stock_ticker || !this.entity.stock_region) {
        return '';
      }
      return `flag:${this.entity.stock_region}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.entity-title {
  h4 {
    font-weight: 600;
    font-size: 28px;
  }
  .entity-title-light {
    color: $aon-teal;
  }
  .entity-title-dark {
    color: $white;
  }
  span {
    color: $aon-gray-06;
  }
  .country-flag {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    position: relative;
    top: 5px;
    img {
      height: 100%;
    }
  }
}
</style>
