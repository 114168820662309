<template>
  <div>
    <span class="blue-smoke--text">{{ trimmedDescription }}</span>
    <a v-if="description" class="read-more" @click="readMore = !readMore">
      {{ $t('entityHeader.readMore') }}
    </a>
    <v-dialog
      :value="readMore"
      max-width="40em"
      scrollable
      @click:outside="cancelDescriptionDialog()"
    >
      <Card style="max-height: 80vh" :headline="!!title ? title : ''">
        <template #actionIcon>
          <AppButton variation="icon" @click="cancelDescriptionDialog()">
            <i class="fas fa-times"></i>
          </AppButton>
        </template>
        {{ description }}
      </Card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Description',
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      readMore: false,
    };
  },
  computed: {
    trimmedDescription() {
      if (!this.description) {
        return '';
      }
      return `${this.description.slice(0, 100).trimEnd()}...`;
    },
  },
  methods: {
    cancelDescriptionDialog() {
      this.readMore = false;
    },
  },
};
</script>
