<template>
  <div class="cpi-opportunity">
    <div class="sticky-header">
      <EntityHeader
        :loading="loadingEntity"
        :entity="entity"
        :collapsed="collapseHeader"
      />
      <v-tabs
        v-model="tab"
        class="entity-tabs"
        show-arrows=""
        background-color="#003C55"
        slider-color="aon-teal-light"
        slider-size="4"
        dark
      >
        <v-tab
          class="tab"
          style="text-transform: none; color: white !important;"
        >
          General Information
        </v-tab>
        <v-tab
          class="tab"
          style="text-transform: none; color: white !important;"
        >
          Activity
        </v-tab>
        <v-tab
          class="tab"
          style="text-transform: none; color: white !important;"
        >
          Metrics
        </v-tab>
        <v-tab
          class="tab"
          style="text-transform: none; color: white !important;"
        >
          Tasks
        </v-tab>
      </v-tabs>
      <div class="tabs" style="border-top: 1px solid white">
        <div v-show="tab === 0" class="general-tab">
          <div class="d-block mx-auto px-10" style="max-width: 900px;">
            <v-container>
              <v-sheet class="elevation-1 pa-6 mb-6">
                <h3 class="mb-4">Opportunity Information</h3>
                <v-row dense>
                  <v-col>
                    <LabeledInput :label="$t('cpiOpportunity.form.result')">
                      <v-text-field
                        :value="
                          cpiOpportunity.result
                            ? $t(
                                `cpiOpportunity.results.${cpiOpportunity.result}`
                              )
                            : ''
                        "
                        hide-details
                        dense
                        outlined
                        readonly
                        @click="showResultEditDialog = true"
                      />
                    </LabeledInput>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <LabeledInput :label="$t('cpiOpportunity.form.dealLead')">
                      <v-text-field
                        v-model="cpiOpportunity.dealLead"
                        hide-details
                        dense
                        outlined
                      />
                    </LabeledInput>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <LabeledInput
                      :label="$t('cpiOpportunity.form.targetCloseDate')"
                    >
                      <DatePicker :date.sync="targetCloseDate" label="" dense />
                    </LabeledInput>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <LabeledInput
                      v-if="
                        cpiOpportunity.maxLoanAmount &&
                          cpiOpportunity.maxLoanAmount > 0
                      "
                      :label="$t('cpiOpportunity.form.minLoanAmount')"
                    >
                      <v-text-field
                        v-model.number="cpiOpportunity.minLoanAmount"
                        class="hide-number-spinner"
                        suffix="($M)"
                        type="number"
                        :error-messages="
                          getError(
                            $v.cpiOpportunity.minLoanAmount,
                            'minLoanAmount'
                          )
                        "
                        dense
                        outlined
                      />
                    </LabeledInput>
                    <LabeledInput
                      v-else
                      :label="$t('cpiOpportunity.form.minLoanAmount')"
                    >
                      <v-text-field
                        value=""
                        class="hide-number-spinner"
                        suffix="($M)"
                        type="number"
                        disabled
                        hide-details
                        dense
                        outlined
                      />
                    </LabeledInput>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <LabeledInput
                      :label="$t('cpiOpportunity.form.maxLoanAmount')"
                    >
                      <v-text-field
                        v-model.number="cpiOpportunity.maxLoanAmount"
                        class="hide-number-spinner"
                        suffix="($M)"
                        type="number"
                        :error-messages="
                          getError(
                            $v.cpiOpportunity.maxLoanAmount,
                            'maxLoanAmount'
                          )
                        "
                        dense
                        outlined
                      />
                    </LabeledInput>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-sheet class="elevation-1 pa-6 mt-6 mb-6">
                <h3 class="mb-4">Lender Information</h3>
                <v-row dense>
                  <v-col>
                    <LabeledInput :label="$t('cpiOpportunity.form.lender')">
                      <v-select
                        v-model="cpiOpportunity.lenderName"
                        :items="lenderItems"
                        placeholder="Select Lender"
                        hide-details
                        dense
                        outlined
                      />
                    </LabeledInput>
                  </v-col>
                </v-row>
                <v-row v-if="cpiOpportunity.lenderName === 'Other'" dense>
                  <v-col>
                    <LabeledInput :label="$t('cpiOpportunity.form.lenderName')">
                      <v-text-field
                        v-model="cpiOpportunity.otherLenderName"
                        hide-details
                        dense
                        outlined
                      />
                    </LabeledInput>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <LabeledInput
                      :label="$t('cpiOpportunity.form.valuationPriority')"
                    >
                      <v-select
                        v-model.number="cpiOpportunity.valuationPriority"
                        :items="valuationPriorityItems"
                        hide-details
                        dense
                        outlined
                      />
                    </LabeledInput>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <LabeledInput
                      :label="$t('cpiOpportunity.form.exclusivityExpiration')"
                    >
                      <DatePicker
                        :date.sync="exclusivityExpiration"
                        label=""
                        dense
                      />
                    </LabeledInput>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-sheet class="elevation-1 pa-6 mt-6 mb-6">
                <h3 class="mb-4">Insurer Information</h3>
                <v-row dense>
                  <v-col>
                    <LabeledInput
                      :label="$t('cpiOpportunity.form.insurerName')"
                      required
                    >
                      <v-text-field
                        v-model="cpiOpportunity.insurerName"
                        dense
                        outlined
                        hide-details
                      />
                    </LabeledInput>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-container>
          </div>
        </div>
        <div v-show="tab === 1" class="activity-tab">
          <div class="d-block mx-auto px-10" style="max-width: 900px;">
            <v-container>
              <v-sheet class="elevation-1 pa-6 mt-6 mb-6">
                <h3 class="mb-4">Activity</h3>
                <v-row dense>
                  <v-col>
                    <LabeledInput :label="$t('cpiOpportunity.form.nextSteps')">
                      <v-textarea
                        v-model="cpiOpportunity.nextSteps"
                        auto-grow
                        outlined
                        hide-details
                      />
                    </LabeledInput>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <LabeledInput :label="$t('cpiOpportunity.form.notes')">
                      <v-textarea
                        v-model="cpiOpportunity.notes"
                        hide-details
                        auto-grow
                        outlined
                      />
                    </LabeledInput>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-container>
          </div>
        </div>
        <div v-show="tab === 2" class="metrics-tab">
          <div class="matrix-wrapper pr-10 d-flex justify-space-between">
            <v-sheet class="metrics-nav elevation-2 mx-4 mt-8">
              <v-tabs :key="tab" v-model="metricsTab" vertical>
                <v-tab>
                  Credit Metrics
                </v-tab>
                <v-tab>
                  Collateral Metrics
                </v-tab>
              </v-tabs>
            </v-sheet>
            <SegmentationMatrix
              :key="`${creditScore}_${collateralScore}`"
              :credit-score="creditScore"
              :collateral-score="collateralScore"
            />
          </div>

          <MetricsForm
            :tab="metricsTab"
            class="metrics-form"
            @creditScoreChanged="creditScoreChanged"
            @collateralScoreChanged="collateralScoreChanged"
            @creditAnswersChanged="creditAnswersChanged"
            @collateralAnswersChanged="collateralAnswersChanged"
          >
            <template v-slot:credit-summary>
              <v-row dense>
                <v-col>
                  <LabeledInput
                    :label="$t('cpiOpportunity.form.creditAnalysis')"
                  >
                    <v-select
                      v-model="cpiOpportunity.creditAnalysis"
                      :items="creditAnalysisItems"
                      hide-details
                      dense
                      outlined
                    />
                  </LabeledInput>
                </v-col>
              </v-row>
              <v-row dense class="mb-2">
                <v-col>
                  <LabeledInput
                    :label="$t('cpiOpportunity.form.creditAnalysisSummary')"
                  >
                    <v-textarea
                      v-model="cpiOpportunity.creditAnalysisSummary"
                      auto-grow
                      hide-details
                      outlined
                    />
                  </LabeledInput>
                </v-col>
              </v-row>
            </template>
            <template v-slot:collateral-summary>
              <v-row dense>
                <v-col>
                  <LabeledInput
                    :label="$t('cpiOpportunity.form.patentAnalysis')"
                  >
                    <v-select
                      v-model="cpiOpportunity.patentAnalysis"
                      :items="patentAnalysisItems"
                      hide-details
                      dense
                      outlined
                    />
                  </LabeledInput>
                </v-col>
              </v-row>
              <v-row dense class="mb-2">
                <v-col>
                  <LabeledInput
                    :label="$t('cpiOpportunity.form.patentAnalysisSummary')"
                  >
                    <v-textarea
                      v-model="cpiOpportunity.patentAnalysisSummary"
                      auto-grow
                      hide-details
                      outlined
                    />
                  </LabeledInput>
                </v-col>
              </v-row>
            </template>
          </MetricsForm>
        </div>
        <div v-show="tab === 3" class="tasks-tab">
          <div class="d-block mx-auto px-10 mt-6" style="max-width: 900px;">
            <v-row>
              <v-col>
                <v-sheet class="elevation-5 pa-6 mb-6">
                  <v-row class="mx-1">
                    <v-col
                      cols="2"
                      class="subtitle-1 font-weight-bold text-center"
                    >
                      {{ $t('cpiOpportunity.milestone') }}
                    </v-col>
                    <v-col
                      cols="3"
                      class="subtitle-1 font-weight-bold text-center"
                    >
                      {{ $t('cpiOpportunity.responsibility') }}
                    </v-col>
                    <v-col
                      cols="3"
                      class="subtitle-1 font-weight-bold text-center"
                    >
                      {{ $t('cpiOpportunity.status') }}
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            icon
                            v-on="on"
                          >
                            <i class="fas fa-sliders-v"></i>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-list-item-title>
                              <v-checkbox
                                v-model="displayTaskItemStatuses"
                                class="ma-0"
                                :label="
                                  $t(
                                    'cpiOpportunity.taskItemStatusItems.notStarted'
                                  )
                                "
                                value="Not Started"
                                color="warning"
                                hide-details
                            /></v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-title>
                              <v-checkbox
                                v-model="displayTaskItemStatuses"
                                class="ma-0"
                                :label="
                                  $t(
                                    'cpiOpportunity.taskItemStatusItems.inProgress'
                                  )
                                "
                                value="In Progress"
                                color="success"
                                hide-details
                            /></v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-title>
                              <v-checkbox
                                v-model="displayTaskItemStatuses"
                                class="ma-0"
                                :label="
                                  $t('cpiOpportunity.taskItemStatusItems.done')
                                "
                                value="Done"
                                color="success"
                                hide-details
                            /></v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-title>
                              <v-checkbox
                                v-model="displayTaskItemStatuses"
                                class="ma-0 mb-2"
                                :label="
                                  $t('cpiOpportunity.taskItemStatusItems.NA')
                                "
                                value="N/A"
                                color="default"
                                hide-details
                            /></v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="4"
                      class="subtitle-1 font-weight-bold text-center"
                    >
                      {{ $t('cpiOpportunity.targetCloseDate') }}
                    </v-col>
                  </v-row>
                </v-sheet>
                <v-sheet
                  v-for="section in taskSections"
                  :key="section"
                  class="elevation-5 pa-6 mb-6"
                >
                  <v-row>
                    <v-col class="my-auto">
                      <h5>
                        {{ $t(`cpiOpportunity.sections.${section}`) }}
                      </h5>
                    </v-col>
                    <v-col cols="auto">
                      <AppButton
                        variation="icon"
                        @click="addTaskItemDialog(section)"
                      >
                        <i class="fas fa-plus" />
                      </AppButton>
                    </v-col>
                  </v-row>
                  <template v-for="(item, i) in getSectionTaskItems(section)">
                    <v-row :key="`${i}_task`">
                      <template v-if="item.readonly">
                        <v-col cols="2" class="my-auto text-center">
                          {{ item.title }}
                        </v-col>
                      </template>
                      <template v-else>
                        <v-col cols="2" class="mt-4">
                          <v-text-field v-model="item.title" outlined dense />
                        </v-col>
                      </template>
                      <v-col cols="3" class="my-auto">
                        <v-text-field
                          v-model="item.responsibility"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col cols="3" class="mt-2 mx-auto">
                        <v-radio-group
                          v-model="item.status"
                          class="action-item-status"
                          hide-details
                          row
                        >
                          <v-radio
                            :label="
                              $t(
                                'cpiOpportunity.taskItemStatusItems.notStarted'
                              )
                            "
                            value="Not Started"
                            color="warning"
                          />
                          <v-radio
                            :label="
                              $t(
                                'cpiOpportunity.taskItemStatusItems.inProgress'
                              )
                            "
                            value="In Progress"
                            color="success"
                          />
                          <v-radio
                            :label="
                              $t('cpiOpportunity.taskItemStatusItems.done')
                            "
                            value="Done"
                            color="success"
                          />
                          <v-radio
                            :label="$t('cpiOpportunity.taskItemStatusItems.NA')"
                            value="N/A"
                            color="default"
                          />
                        </v-radio-group>
                      </v-col>
                      <v-col cols="4" class="my-auto">
                        <DatePicker
                          :date="formatDatePicker(item.targetDate)"
                          label=""
                          dense
                        />
                      </v-col>
                    </v-row>
                  </template>
                </v-sheet>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showResultEditDialog"
      width="400"
      @click:outside="showResultEditDialog = false"
    >
      <v-card>
        <v-card-title>
          {{ $t('cpiOpportunity.editStatus') }}
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="opportunityResult">
            <template #label>
              {{ $t('cpiOpportunity.opportunityResultTitle') }}
            </template>
            <v-radio
              v-for="openResult in openOpportunityResults"
              :key="openResult"
              :label="$t(`cpiOpportunity.results.${openResult}`)"
              :value="openResult"
            />
            <v-radio
              v-for="closedResult in closedOpportunityResults"
              :key="closedResult"
              :label="$t(`cpiOpportunity.results.${closedResult}`)"
              :value="closedResult"
            />
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <AppButton :loading="saving" @click="saveOpportunityResult">
            {{ $t('cpiOpportunity.save') }}
          </AppButton>
          <AppButton
            variation="ghost"
            :loading="saving"
            @click="showResultEditDialog = false"
          >
            {{ $t('cpiOpportunity.cancel') }}
          </AppButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showAddTaskItemDialog"
      @click:outside="showAddTaskItemDialog = false"
    >
      <v-card>
        <v-card-title>
          {{ $t('cpiOpportunity.addTaskItem') }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="auto">Milestone</v-col>
              <v-col>
                <v-text-field v-model="customTaskItemName" outlined dense />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <AppButton
            :loading="saving"
            :disabled="!customTaskItemName"
            @click="saveCustomTaskItem"
          >
            {{ $t('cpiOpportunity.save') }}
          </AppButton>
          <AppButton
            variation="ghost"
            :loading="saving"
            @click="showAddTaskItemDialog = false"
          >
            {{ $t('cpiOpportunity.cancel') }}
          </AppButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AppButton
      style="min-width:10rem;position:fixed;right:5px;bottom:20px"
      :loading="saving"
      :disabled="loading || !cpiOpportunity.borrowerName.trim()"
      @click="updateCpiOpportunity"
    >
      {{ $t('cpiOpportunity.saveChanges') }}
    </AppButton>
  </div>
</template>

<script>
import {
  updateCpiOpportunity,
  createOpportunityTask,
  getIpAlphaEntities,
  getCpiOpportunity,
} from '@/api/cpiOpportunities';
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
import DatePicker from '@aon/cfs-components/src/components/Forms/DatePickerTextField';
import { minValue, numeric } from 'vuelidate/lib/validators';
import scrollToFirstError from '@aon/cfs-components/src/mixins/scroll-to-first-error';
import EntityHeader from '@/components/IPAlphaComponents/EntityHeader.vue';
import SegmentationMatrix from '@/components/CpiOpportunity/SegmentationMatrix.vue';
import MetricsForm from '@/components/CpiOpportunity/MetricsForm.vue';
import { saveCollateralAnswers, saveCreditAnswers } from '@/lib/helpers';

export default {
  name: 'CpiOpportunityV2',
  components: {
    MetricsForm,
    SegmentationMatrix,
    EntityHeader,
    LabeledInput,
    DatePicker,
  },
  mixins: [scrollToFirstError],
  data() {
    return {
      saving: false,
      loading: false,
      loadingEntity: true,
      entity: null,
      cpiOpportunity: {},
      cpiOpportunityTaskItems: {},
      showResultEditDialog: false,
      showAddTaskItemDialog: false,
      selectedSection: null,
      customTaskItemName: null,
      opportunityResult: null,
      selectedLender: null,
      otherLenderName: null,
      creditScore: 0,
      creditAnswers: null,
      collateralScore: 0,
      collateralAnswers: null,
      displayTaskItemStatuses: ['Not Started', 'In Progress', 'Done', 'N/A'],
      openOpportunityResults: [
        'early',
        'icDiligence',
        'termSheetIssued',
        'termSheetSigned',
      ],
      closedOpportunityResults: [
        'lenderDeclined',
        'borrowerDeclined',
        'loanIssued',
      ],
      lenderItems: [
        {
          text: 'None',
          value: null,
        },
        {
          text: this.$t('cpiOpportunity.lenderItems.aon'),
          value: 'Aon Fund',
        },
        {
          text: this.$t('cpiOpportunity.lenderItems.atlas'),
          value: 'Atlas',
        },
        {
          text: this.$t('cpiOpportunity.lenderItems.bain'),
          value: 'Bain',
        },
        {
          text: this.$t('cpiOpportunity.lenderItems.jefferies'),
          value: 'Jefferies',
        },
        {
          text: this.$t('cpiOpportunity.lenderItems.other'),
          value: 'Other',
        },
      ],
      creditAnalysisItems: [
        {
          text: this.$t('cpiOpportunity.creditAnalysisItems.excellent'),
          value: 'Excellent',
        },
        {
          text: this.$t('cpiOpportunity.creditAnalysisItems.adequate'),
          value: 'Adequate',
        },
        {
          text: this.$t('cpiOpportunity.creditAnalysisItems.poor'),
          value: 'Poor',
        },
      ],
      patentAnalysisItems: [
        {
          text: this.$t('cpiOpportunity.patentAnalysisItems.strong'),
          value: 'Strong',
        },
        {
          text: this.$t('cpiOpportunity.patentAnalysisItems.sellable'),
          value: 'Sellable',
        },
        {
          text: this.$t('cpiOpportunity.patentAnalysisItems.poor'),
          value: 'Poor',
        },
        {
          text: this.$t('cpiOpportunity.patentAnalysisItems.reject'),
          value: 'Reject',
        },
        {
          text: this.$t('cpiOpportunity.patentAnalysisItems.undetermined'),
          value: 'Undetermined',
        },
      ],
      valuationPriorityItems: [
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.noValuation'),
          value: null,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.1'),
          value: 1,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.2'),
          value: 2,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.3'),
          value: 3,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.4'),
          value: 4,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.5'),
          value: 5,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.6'),
          value: 6,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.7'),
          value: 7,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.8'),
          value: 8,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.9'),
          value: 9,
        },
      ],
      taskSections: [
        'prepareForDiligence',
        'diligencePhase',
        'alignMarkets',
        'negotiateTerms',
        'execution',
      ],
      tab: 0,
      metricsTab: 0,
      collapseHeader: false,
    };
  },
  validations: {
    cpiOpportunity: {
      minLoanAmount: {
        numeric,
        minValue: minValue(0),
        maxValue: (value, fields) =>
          !!fields.maxLoanAmount && fields.maxLoanAmount > 0
            ? value < fields.maxLoanAmount
            : true,
      },
      maxLoanAmount: {
        numeric,
        minValue: minValue(0),
      },
    },
  },
  computed: {
    targetCloseDate: {
      get() {
        return this.formatDatePicker(this.cpiOpportunity.targetCloseDate);
      },
      set(value) {
        this.cpiOpportunity.targetCloseDate = value;
      },
    },
    exclusivityExpiration: {
      get() {
        return this.formatDatePicker(this.cpiOpportunity.exclusivityExpiration);
      },
      set(value) {
        this.cpiOpportunity.exclusivityExpiration = value;
      },
    },
  },
  async created() {
    this.loading = true;
    this.loadingEntity = true;
    try {
      const opportunityResult = await getCpiOpportunity(this.$route.params.id);
      this.cpiOpportunity = opportunityResult.data;
      const taskItems = this.taskSections.reduce((items, cur) => {
        items[cur] = [];
        return items;
      }, this.cpiOpportunityTaskItems);
      this.cpiOpportunity.taskItems.forEach(item =>
        taskItems[item.section].push(item)
      );
      this.cpiOpportunityTaskItems = taskItems;
      this.opportunityResult = this.cpiOpportunity.result;

      if (!this.entity) {
        if (this.cpiOpportunity.entityId) {
          const { data } = await getIpAlphaEntities({
            from: 0,
            size: 1,
            query: {
              bool: {
                filter: [
                  { match: { aon_entity_pk: this.cpiOpportunity.entityId } },
                ],
              },
            },
          });
          this.entity = data.hits.hits.map(h => h._source)[0];
        } else {
          // default empty entity for all the opportunity records that don't have an entityId
          this.entity = {
            aon_entity_pk: null,
            name: this.cpiOpportunity.borrowerName,
            description: null,
            entity_type: null,
            address: null,
            hq_country: null,
            parent_aon_entity_name: null,
            parent_aon_entity_pk: null,
            ultimate_aon_entity_name: null,
            ultimate_aon_entity_pk: null,
            overall_score: 0,
            coverage_score: 0,
            opportunity_score: 0,
            risk_score: 0,
            power_score: 0,
            velocity_score: 0,
            final_diversity_score: 0,
            total_revenue: null,
            revenue_end_date: null,
            stock_ticker: null,
            stock_exchange: null,
            stock_region: null,
            patent_count: null,
            apex_score: 0,
          };
        }
      }
    } finally {
      this.loading = false;
      this.loadingEntity = false;
    }
    window.addEventListener('scroll', this.windowScroll);
  },
  methods: {
    windowScroll() {
      this.collapseHeader =
        document.documentElement.scrollTop > 0 || document.body.scrollTop > 0;
    },
    creditScoreChanged(creditScore) {
      this.creditScore = creditScore;
    },
    collateralScoreChanged(collateralScore) {
      this.collateralScore = collateralScore;
    },
    creditAnswersChanged(creditAnswers) {
      this.creditAnswers = creditAnswers;
    },
    collateralAnswersChanged(collateralAnswers) {
      this.collateralAnswers = collateralAnswers;
    },
    getError(model, fieldName) {
      const errors = [];
      switch (fieldName) {
        case 'minLoanAmount':
          !model.minValue && errors.push('Value must be greater than 0');
          !model.maxValue &&
            errors.push('Value must be less than Max Loan Amount');
          break;
        case 'maxLoanAmount':
          !model.minValue && errors.push('Value must be greater than 0');
          break;
      }
      return errors;
    },
    getSectionTaskItems(section) {
      let taskItems = this.cpiOpportunityTaskItems[section] || [];

      if (this.displayTaskItemStatuses.length > 0) {
        taskItems = taskItems.filter(taskItem =>
          this.displayTaskItemStatuses.includes(taskItem.status)
        );
      }

      return taskItems;
    },
    async updateCpiOpportunity() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.scrollToFirstError();
        return;
      }
      this.saving = true;
      if (!this.cpiOpportunity.minLoanAmount) {
        this.cpiOpportunity.minLoanAmount = null;
      }
      if (!this.cpiOpportunity.maxLoanAmount) {
        this.cpiOpportunity.maxLoanAmount = null;
      }

      try {
        await updateCpiOpportunity(this.cpiOpportunity.id, this.cpiOpportunity);
        saveCreditAnswers(this.cpiOpportunity.id, this.creditAnswers);
        saveCollateralAnswers(this.cpiOpportunity.id, this.collateralAnswers);

        this.$AppEventBus.$emit('snacktime', {
          type: 'success',
          message: 'Cpi Opportunity Changes Have Been Saved',
        });
      } catch (e) {
        this.$AppEventBus.$emit('snacktime', {
          type: 'error',
          message: 'Unable to update Cpi Opportunity',
        });
      }
      this.saving = false;
    },
    formatDatePicker(date) {
      return date ? this.$moment(date).format('YYYY-MM-DD') : null;
    },
    async saveOpportunityResult() {
      this.cpiOpportunity.status = 'closed';
      if (this.openOpportunityResults.some(r => r === this.opportunityResult)) {
        this.cpiOpportunity.status = 'open';
      }
      this.cpiOpportunity.result = this.opportunityResult;
      await this.updateCpiOpportunity();
      this.showResultEditDialog = false;
    },
    addTaskItemDialog(section) {
      this.customTaskItemName = null;
      this.selectedSection = section;
      this.showAddTaskItemDialog = true;
    },
    async saveCustomTaskItem() {
      this.saving = true;
      const section = this.cpiOpportunityTaskItems[this.selectedSection];
      const max = section.reduce(
        (prev, cur) => (prev < cur.step ? cur.step : prev),
        0
      );
      const newTask = await createOpportunityTask({
        title: this.customTaskItemName,
        section: this.selectedSection,
        opportunityId: this.cpiOpportunity.id,
        step: max + 1,
        status: 'Not Started',
      });
      section.push(newTask);

      this.saving = false;
      this.showAddTaskItemDialog = false;
    },
  },
};
</script>

<style lang="scss">
.cpi-opportunity {
  max-width: 100%;
  padding: 0;
}
.metrics-nav {
  min-width: 200px;
  width: 200px;
  height: 100px;
  margin-right: auto;
}
.metrics-form {
  margin-top: -424px;
  margin-left: 232px;
}
.matrix-wrapper {
  position: sticky;
  top: 210px;
}
.sticky-header {
  position: sticky;
  top: 64px;
  height: 100%;
  overflow-anchor: none;
}
.entity-tabs {
  position: sticky;
  top: 185px;
  z-index: 100;
}
.general-tab,
.activity-tab,
.metrics-tab,
.tasks-tab {
  margin-top: 25px;
  min-height: 100vh;
}
</style>
