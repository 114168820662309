<template>
  <div
    class="cor-score-radar-widget row"
    :class="[
      graphSize === 'normal' ? 'ma-0 pt-8 mb-8' : '',
      themeColor === 'dark'
        ? 'dark-background'
        : themeColor === 'medium'
        ? 'medium-background'
        : 'light-background',
      graphSize === 'small' ? 'small-widget' : '',
    ]"
  >
    <NoData v-if="noData" class="no-data" />

    <div v-if="graphSize === 'small'" class="d-flex flex-column mt-4 ml-5">
      <p
        class="cor-score-small mb-1"
        :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
      >
        {{ $t(`corScoreRadarWidget.labels.corScore`) }}
        <span class="trade-mark pr-1">{{
          $t(`corScoreRadarWidget.labels.tradeMark`)
        }}</span>
        <DefinitionTooltip
          :title="$t('definitionTooltips.cor.title')"
          :definition="$t('definitionTooltips.cor.definition')"
          :more-info="$t('definitionTooltips.cor.more')"
          :white="themeColor === 'dark'"
          bottom
        />
      </p>

      <span
        class="cor-score-display-small"
        :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
        >{{ corScoreValue(corScore.overallScore) }}</span
      >
    </div>

    <div
      v-if="graphSize === 'medium'"
      class="row mt-5 ml-0 mr-0 pb-5 medium-header"
    >
      <div class="d-flex pt-0 ml-auto">
        <p
          class="cor-score-medium"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
        >
          {{ $t(`corScoreRadarWidget.labels.corScore`) }}
          <span class="trade-mark">{{
            $t(`corScoreRadarWidget.labels.tradeMark`)
          }}</span>
          <DefinitionTooltip
            :title="$t('definitionTooltips.cor.title')"
            :definition="$t('definitionTooltips.cor.definition')"
            :more-info="$t('definitionTooltips.cor.more')"
            :white="themeColor === 'dark'"
            bottom
          />
        </p>
      </div>
      <div class="d-flex pt-0 ml-auto mr-auto">
        <span class="cor-score-display-text-medium">
          {{ $t(`corScoreRadarWidget.labels.corScoreDisplayText`) }}
        </span>
        <div class="cor-score-display-medium">
          {{ corScoreValue(corScore.overallScore) }}
        </div>
      </div>
    </div>

    <div
      class="d-flex flex-column col-xs-6 graph-wrapper"
      :class="[
        graphSize === 'normal'
          ? 'mr-auto ml-8'
          : graphSize === 'medium'
          ? 'mr-5 ml-5'
          : '',
      ]"
    >
      <div
        v-if="!noData"
        :class="
          graphSize === 'normal'
            ? ''
            : graphSize === 'medium'
            ? 'mt-auto mb-auto'
            : ''
        "
      >
        <div v-if="graphSize === 'normal'">
          <p
            class="cor-score"
            :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
          >
            {{ $t(`corScoreRadarWidget.labels.corScore`) }}
            <span class="trade-mark mr-1">{{
              $t(`corScoreRadarWidget.labels.tradeMark`)
            }}</span>
            <DefinitionTooltip
              :title="$t('definitionTooltips.cor.title')"
              :definition="$t('definitionTooltips.cor.definition')"
              :more-info="$t('definitionTooltips.cor.more')"
              :white="themeColor === 'dark'"
              bottom
            />
          </p>
        </div>
      </div>
      <div
        :class="
          graphSize === 'normal'
            ? 'mt-7 mb-5'
            : graphSize === 'small'
            ? ''
            : 'mt-7'
        "
      >
        <!-- Opportunity Chart Label      -->
        <div
          class="chart-label-wrapper mx-auto"
          :class="
            graphSize === 'normal'
              ? 'top-label'
              : graphSize === 'medium'
              ? 'top-label-medium'
              : 'top-label-small'
          "
        >
          <p
            :class="[
              themeColor !== 'dark' ? 'dark-text' : 'light-text',
              graphSize === 'normal' ? 'chart-label' : 'chart-label-small',
            ]"
          >
            {{ $t(`corScoreRadarWidget.labels.opportunityAbbreviate`) }}
          </p>
        </div>
        <canvas
          ref="chart"
          :class="
            graphSize === 'normal'
              ? 'canvas'
              : graphSize === 'small'
              ? 'canvasSmall'
              : 'canvasMedium'
          "
        />
        <div class="d-flex justify-space-between">
          <!-- Coverage Chart Label      -->
          <div
            class="chart-label-wrapper"
            :class="
              graphSize === 'medium'
                ? 'mt-9 ml-5'
                : graphSize === 'normal'
                ? 'ml-4 mt-4'
                : 'ml-4'
            "
          >
            <p
              :class="[
                themeColor !== 'dark' ? 'dark-text' : 'light-text',
                graphSize === 'normal' ? 'chart-label' : 'chart-label-small',
              ]"
            >
              {{ $t(`corScoreRadarWidget.labels.coverageAbbreviate`) }}
            </p>
          </div>
          <!-- Risk Chart Label      -->
          <div
            class="chart-label-wrapper"
            :class="
              graphSize === 'medium'
                ? 'mt-9 mr-4'
                : graphSize === 'normal'
                ? 'mt-4'
                : 'mr-4'
            "
          >
            <p
              :class="[
                themeColor !== 'dark' ? 'dark-text' : 'light-text',
                graphSize === 'normal' ? 'chart-label' : 'chart-label-small',
              ]"
            >
              {{ $t(`corScoreRadarWidget.labels.riskAbbreviate`) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--  Normal Legend   -->
    <div
      v-if="graphSize === 'normal'"
      class="flex-column cor-chart-legend col-xs-6 mr-auto ml-auto mb-4"
    >
      <div class="row">
        <p
          class="cor-chart-legend-label"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
        >
          {{ $t(`corScoreRadarWidget.labels.coverage`) }}
          <DefinitionTooltip
            :title="$t('definitionTooltips.coverage.title')"
            :definition="$t('definitionTooltips.coverage.definition')"
            :more-info="$t('definitionTooltips.coverage.more')"
            :white="themeColor === 'dark'"
            bottom
          />
        </p>
      </div>
      <div class="row mt-0">
        <div
          v-if="corScoreValue(corScore.coverageScore)"
          class="cor-chart-legend-scale mb-auto mt-auto"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
          :style="{
            background: `linear-gradient(to right,  ${corScoreColor} 0%, ${corScoreColor} ${corScoreValue(
              corScore.coverageScore
            )}%, #F9FCFC ${corScoreValue(
              corScore.coverageScore
            )}%, #F9FCFC 100%)`,
          }"
        ></div>
        <div
          v-else
          class="cor-chart-legend-scale mb-auto mt-auto no-score"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
        ></div>
        <span
          class="legend-score"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
          >{{ displayCorScore(corScore.coverageScore) }}</span
        >
      </div>
      <div class="row">
        <p
          class="cor-chart-legend-label"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
        >
          {{ $t(`corScoreRadarWidget.labels.opportunity`) }}
          <DefinitionTooltip
            :title="$t('definitionTooltips.opportunity.title')"
            :definition="$t('definitionTooltips.opportunity.definition')"
            :more-info="$t('definitionTooltips.opportunity.more')"
            :white="themeColor === 'dark'"
            bottom
          />
        </p>
      </div>
      <div class="row mt-0">
        <div
          v-if="corScoreValue(corScore.opportunityScore)"
          class="cor-chart-legend-scale mb-auto mt-auto"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
          :style="{
            background: `linear-gradient(to right,  ${opportunityScoreColor} 0%, ${opportunityScoreColor} ${corScoreValue(
              corScore.opportunityScore
            )}%, #F9FCFC ${corScoreValue(
              corScore.opportunityScore
            )}%, #F9FCFC 100%)`,
          }"
        ></div>
        <div
          v-else
          class="cor-chart-legend-scale mb-auto mt-auto no-score"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
        ></div>
        <span
          class="legend-score"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
          >{{ displayCorScore(corScore.opportunityScore) }}</span
        >
      </div>
      <div class="row">
        <p
          class="cor-chart-legend-label"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
        >
          {{ $t(`corScoreRadarWidget.labels.risk`) }}
          <DefinitionTooltip
            :title="$t('definitionTooltips.risk.title')"
            :definition="$t('definitionTooltips.risk.definition')"
            :more-info="$t('definitionTooltips.risk.more')"
            :white="themeColor === 'dark'"
            bottom
          />
        </p>
      </div>
      <div class="row mt-0">
        <div
          v-if="corScoreValue(corScore.riskScore)"
          class="cor-chart-legend-scale mb-auto mt-auto"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
          :style="{
            background: `linear-gradient(to right,  ${riskScoreColor} 0%, ${riskScoreColor} ${corScoreValue(
              corScore.riskScore
            )}%, #F9FCFC ${corScoreValue(corScore.riskScore)}%, #F9FCFC 100%)`,
          }"
        ></div>
        <div
          v-else
          class="cor-chart-legend-scale mb-auto mt-auto no-score"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
        ></div>
        <span
          class="legend-score"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
          >{{ displayCorScore(corScore.riskScore) }}</span
        >
      </div>
      <div class="row mt-8">
        <span
          class="cor-score-display-text"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
        >
          {{ $t(`corScoreRadarWidget.labels.corScoreDisplayText`) }}
        </span>
        <div
          class="cor-score-display"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
        >
          {{ corScoreValue(corScore.overallScore) }}
        </div>
      </div>
    </div>
    <!-- Small Legend-->
    <div
      v-if="graphSize === 'small'"
      class="flex-column cor-chart-legend-small mr-5 mt-1 ml-auto"
    >
      <div class="row mt-0 mb-0">
        <p
          class="cor-chart-legend-label mr-auto"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
        >
          {{ $t(`corScoreRadarWidget.labels.coverage`) }}
          <DefinitionTooltip
            :title="$t('definitionTooltips.coverage.title')"
            :definition="$t('definitionTooltips.coverage.definition')"
            :more-info="$t('definitionTooltips.coverage.more')"
            :white="themeColor === 'dark'"
            bottom
          />
        </p>
        <div
          v-if="corScoreValue(corScore.coverageScore)"
          class="cor-chart-legend-scale mb-auto mt-auto"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
          :style="{
            background: `linear-gradient(to right,  ${corScoreColor} 0%, ${corScoreColor} ${corScoreValue(
              corScore.coverageScore
            )}%, #F9FCFC ${corScoreValue(
              corScore.coverageScore
            )}%, #F9FCFC 100%)`,
          }"
        ></div>
        <div
          v-else
          class="cor-chart-legend-scale mb-auto mt-auto no-score"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
        ></div>
        <span
          class="legend-score"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
          >{{ displayCorScore(corScore.coverageScore) }}</span
        >
      </div>
      <div class="row mt-0 mb-0">
        <p
          class="cor-chart-legend-label mr-auto"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
        >
          {{ $t(`corScoreRadarWidget.labels.opportunity`) }}
          <DefinitionTooltip
            :title="$t('definitionTooltips.opportunity.title')"
            :definition="$t('definitionTooltips.opportunity.definition')"
            :more-info="$t('definitionTooltips.opportunity.more')"
            :white="themeColor === 'dark'"
            bottom
          />
        </p>
        <div
          v-if="corScoreValue(corScore.opportunityScore)"
          class="cor-chart-legend-scale mb-auto mt-auto"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
          :style="{
            background: `linear-gradient(to right,  ${opportunityScoreColor} 0%, ${opportunityScoreColor} ${corScoreValue(
              corScore.opportunityScore
            )}%, #F9FCFC ${corScoreValue(
              corScore.opportunityScore
            )}%, #F9FCFC 100%)`,
          }"
        ></div>
        <div
          v-else
          class="cor-chart-legend-scale mb-auto mt-auto no-score"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
        ></div>
        <span
          class="legend-score"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
          >{{ displayCorScore(corScore.opportunityScore) }}</span
        >
      </div>
      <div class="row mt-0 mb-0">
        <p
          class="cor-chart-legend-label mr-auto"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
        >
          {{ $t(`corScoreRadarWidget.labels.risk`) }}
          <DefinitionTooltip
            :title="$t('definitionTooltips.risk.title')"
            :definition="$t('definitionTooltips.risk.definition')"
            :more-info="$t('definitionTooltips.risk.more')"
            :white="themeColor === 'dark'"
            bottom
          />
        </p>
        <div
          v-if="corScoreValue(corScore.riskScore)"
          class="cor-chart-legend-scale mb-auto mt-auto"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
          :style="{
            background: `linear-gradient(to right,  ${riskScoreColor} 0%, ${riskScoreColor} ${corScoreValue(
              corScore.riskScore
            )}%, #F9FCFC ${corScoreValue(corScore.riskScore)}%, #F9FCFC 100%)`,
          }"
        ></div>
        <div
          v-else
          class="cor-chart-legend-scale mb-auto mt-auto no-score"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
        ></div>
        <span
          class="legend-score"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
          >{{ displayCorScore(corScore.riskScore) }}</span
        >
      </div>
    </div>

    <!--  Medium Legend   -->
    <div
      v-if="graphSize === 'medium'"
      class="flex-column cor-chart-legend-medium mr-auto"
    >
      <div class="row">
        <p
          class="cor-chart-legend-label"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
        >
          {{ $t(`corScoreRadarWidget.labels.coverage`) }}
          <DefinitionTooltip
            :title="$t('definitionTooltips.coverage.title')"
            :definition="$t('definitionTooltips.coverage.definition')"
            :more-info="$t('definitionTooltips.coverage.more')"
            :white="themeColor === 'dark'"
            bottom
          />
        </p>
      </div>
      <div class="row mt-0">
        <div
          v-if="corScoreValue(corScore.coverageScore)"
          class="cor-chart-legend-scale mb-auto mt-auto"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
          :style="{
            background: `linear-gradient(to right,  ${corScoreColor} 0%, ${corScoreColor} ${corScoreValue(
              corScore.coverageScore
            )}%, #F9FCFC ${corScoreValue(
              corScore.coverageScore
            )}%, #F9FCFC 100%)`,
          }"
        ></div>
        <div
          v-else
          class="cor-chart-legend-scale mb-auto mt-auto no-score"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
        ></div>
        <span
          class="legend-score"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
          >{{ displayCorScore(corScore.coverageScore) }}</span
        >
      </div>
      <div class="row">
        <p
          class="cor-chart-legend-label"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
        >
          {{ $t(`corScoreRadarWidget.labels.opportunity`) }}
          <DefinitionTooltip
            :title="$t('definitionTooltips.opportunity.title')"
            :definition="$t('definitionTooltips.opportunity.definition')"
            :more-info="$t('definitionTooltips.opportunity.more')"
            :white="themeColor === 'dark'"
            bottom
          />
        </p>
      </div>
      <div class="row mt-0">
        <div
          v-if="corScoreValue(corScore.opportunityScore)"
          class="cor-chart-legend-scale mb-auto mt-auto"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
          :style="{
            background: `linear-gradient(to right,  ${opportunityScoreColor} 0%, ${opportunityScoreColor} ${corScoreValue(
              corScore.opportunityScore
            )}%, #F9FCFC ${corScoreValue(
              corScore.opportunityScore
            )}%, #F9FCFC 100%)`,
          }"
        ></div>
        <div
          v-else
          class="cor-chart-legend-scale mb-auto mt-auto no-score"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
        ></div>
        <span
          class="legend-score"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
          >{{ displayCorScore(corScore.opportunityScore) }}</span
        >
      </div>
      <div class="row">
        <p
          class="cor-chart-legend-label"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
        >
          {{ $t(`corScoreRadarWidget.labels.risk`) }}
          <DefinitionTooltip
            :title="$t('definitionTooltips.risk.title')"
            :definition="$t('definitionTooltips.risk.definition')"
            :more-info="$t('definitionTooltips.risk.more')"
            :white="themeColor === 'dark'"
            bottom
          />
        </p>
      </div>
      <div class="row mt-0">
        <div
          v-if="corScoreValue(corScore.riskScore)"
          class="cor-chart-legend-scale mb-auto mt-auto"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
          :style="{
            background: `linear-gradient(to right,  ${riskScoreColor} 0%, ${riskScoreColor} ${corScoreValue(
              corScore.riskScore
            )}%, #F9FCFC ${corScoreValue(corScore.riskScore)}%, #F9FCFC 100%)`,
          }"
        ></div>
        <div
          v-else
          class="cor-chart-legend-scale mb-auto mt-auto no-score"
          :class="themeColor === 'medium' ? 'medium-border' : ''"
        ></div>
        <span
          class="legend-score"
          :class="themeColor !== 'dark' ? 'dark-text' : 'light-text'"
          >{{ displayCorScore(corScore.riskScore) }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import DefinitionTooltip from '@/components/IPAlphaComponents/DefinitionTooltip';
import variables from '@/sass/variables.scss';

export default {
  name: 'CorScoreRadarWidget',
  components: { DefinitionTooltip },
  props: {
    corScore: {
      type: [String, Object],
      default: null,
    },
    graphSize: {
      type: String,
      default: 'normal',
    },
    themeColor: {
      type: String,
      default: 'light',
    },
  },
  data() {
    return {
      chart: null,
      chartConfig: {
        type: 'radar',
        data: {
          labels: ['', '', ''],
          datasets: [
            {
              data: [],
              label: this.$t(`corScoreRadarWidget.labels.corScore`),
              fill: true,
              borderColor:
                this.themeColor === 'light'
                  ? '#262836'
                  : this.themeColor === 'dark'
                  ? '#FFEDCC'
                  : '#46535E',
              pointBackgroundColor: variables.corChartColors.split(','),
            },
          ],
        },
        options: {
          layout: {
            padding: {
              top: this.graphSize === 'normal' ? 0 : 20,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
          responsive: false,
          maintainAspectRation: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          elements: {
            line: {
              borderWidth: this.graphSize === 'normal' ? 2 : 0.75,
            },
            point: {
              radius: this.graphSize === 'normal' ? 4 : 2,
              hoverRadius: this.graphSize === 'normal' ? 4 : 2,
            },
          },
          scales: {
            r: {
              angleLines: {
                display: true,
                color:
                  this.themeColor === 'light'
                    ? 'rgba(38, 40, 54, 0.2)'
                    : this.themeColor === 'dark'
                    ? 'rgba(256,256,256,0.2)'
                    : 'rgba(70, 83, 94, 0.2)',
              },
              pointLabels: {
                display: true,
              },
              min: 0,
              max: 100,
              grid: {
                color:
                  this.themeColor === 'light'
                    ? 'rgba(38, 40, 54, 0.2)'
                    : this.themeColor === 'dark'
                    ? 'rgba(256,256,256,0.2)'
                    : 'rgba(70, 83, 94, 0.2)',
                lineWidth: 1,
              },
              ticks: {
                display: false,
                stepSize: 25,
              },
            },
          },
        },
      },
    };
  },
  computed: {
    noData() {
      return this.corScore === null;
    },
    corChartColors() {
      return variables.corChartColors.split(',');
    },
    corScoreColor() {
      return this.corChartColors[2];
    },
    riskScoreColor() {
      return this.corChartColors[1];
    },
    opportunityScoreColor() {
      return this.corChartColors[0];
    },
  },
  mounted() {
    Chart.register(...registerables);
    const ctx = this.$refs.chart.getContext('2d');
    this.chart = new Chart(ctx, this.chartConfig);
    var chartAreax = this.chart.chartArea;
    this.chart.data.datasets[0].backgroundColor = this.getGradient(chartAreax);
    this.updateChart();
  },
  methods: {
    updateChart() {
      this.chart.data.datasets[0].data = [
        this.corScore.opportunityScore ?? 0,
        this.corScore.riskScore ?? 0,
        this.corScore.coverageScore ?? 0,
      ];
      this.chart.update();
    },
    getGradient(chartAreax) {
      var ctx = this.$refs.chart.getContext('2d');
      if (
        chartAreax == null ||
        chartAreax.height == 0 ||
        chartAreax.width == 0
      ) {
        return;
      } else {
        const centerX = (chartAreax.left + chartAreax.right) / 2;
        const centerY = (chartAreax.top + chartAreax.bottom) / 2;
        const r = Math.min(
          (chartAreax.right - chartAreax.left) / 2,
          (chartAreax.bottom - chartAreax.top) / 2
        );

        const gradient = ctx.createRadialGradient(
          centerX,
          centerY,
          0,
          centerX,
          centerY,
          r
        );
        if (this.themeColor === 'light') {
          gradient.addColorStop(0, 'rgba(38, 40, 54, 0)');
          gradient.addColorStop(0.3, 'rgba(38, 40, 54, 0.5)');
          gradient.addColorStop(1, 'rgba(38, 40, 54, 0.8)');
        } else if (this.themeColor === 'dark') {
          gradient.addColorStop(0, 'rgba(255, 237, 204, 0)');
          gradient.addColorStop(0.6, 'rgba(255, 237, 204, 0.3)');
          gradient.addColorStop(1, 'rgba(255, 237, 204, 0.5)');
        } else {
          gradient.addColorStop(0, 'rgba(0, 60, 85, 0)');
          gradient.addColorStop(0.6, 'rgba(0, 60, 85, 0.3)');
          gradient.addColorStop(1, 'rgba(0, 60, 85, 0.5)');
        }

        ctx.fillStyle = gradient;
        return gradient;
      }
    },
    hasCorScore(type) {
      const score = this.corScore[`${type}Score`];
      return score !== null && score >= 0;
    },
    displayCorScore(s) {
      if (s > 0 && s < 1) {
        return `< 1%`;
      }
      const score = parseInt(s);
      if (score && score !== -1) {
        return `${Math.floor(score)}%`;
      } else {
        return 'N/A';
      }
    },
    corScoreValue(s) {
      const score = parseInt(s);
      if (score && score !== -1) {
        return Math.floor(score);
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss">
.canvas {
  width: 300px !important;
  height: 300px !important;
  margin-top: -66px;
  margin-bottom: -90px;
}

.canvasMedium {
  width: 225px !important;
  height: 225px !important;
  margin-top: -64px;
  margin-bottom: -90px;
}

.canvasSmall {
  width: 115px !important;
  height: 115px !important;
  margin-top: -62px;
  margin-bottom: -30px;
}

.cor-score-radar-widget {
  border: 1px solid rgba(#f9fcfc, 0.4);
  border-radius: 6px;
}

.small-widget {
  margin: 0 0 0 auto;
  max-width: 600px;
}

.light-background {
  background: #e5eff0;
}

.dark-background {
  background: rgba(#262836, 0.4);
}

.medium-background {
  background: #eef6f7;
  border: 1px solid #262836;
}

.cor-score-radar-widget {
  .cor-score {
    margin-bottom: 0px;
    font-weight: 300;
    font-size: 24px;

    .trade-mark {
      font-size: 16px;
    }

    .definition-tooltip {
      font-size: 16px;
    }
  }

  .cor-score-small {
    font-size: 14px;
    font-weight: 700;

    .trade-mark {
      font-size: 12px;
    }
  }

  .medium-header {
    border-bottom: 1px solid #46535e1a;
  }

  .cor-score-medium {
    font-weight: 700;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;

    .trade-mark {
      font-size: 12px;
    }

    .definition-tooltip {
      font-size: 12px;
    }
  }

  .cor-score-display-small {
    font-size: 18px;
    font-weight: 325;
  }
}

.cor-score-radar-widget {
  .cor-chart-legend {
    margin-top: 80px;
    justify-content: center;
    align-items: center;
  }

  .cor-chart-legend-label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .cor-chart-legend-scale {
    width: 237px;
    height: 8px;
    border-radius: 58px;
  }

  .no-score {
    background: #f9fcfc;
  }

  .legend-score {
    font-size: 16px;
    font-weight: 700;
    margin-left: 8px;
  }

  .cor-score-display-text {
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
  }

  .cor-score-display {
    background: #cef3ec;
    width: 54px;
    height: 56px;
    border-radius: 100px;
    color: #12a88a;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 700;
    margin-left: 8px;
    margin-right: auto;
  }

  .cor-score-display-text-medium {
    font-size: 16px;
    font-weight: 700;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }

  .cor-score-display-medium {
    background: #cef3ec;
    width: 38px;
    height: 36px;
    border-radius: 100px;
    color: #12a88a;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 700;
    margin-left: 8px;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
  }
}

.cor-score-radar-widget .cor-chart-legend-small {
  .cor-chart-legend-label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: auto;
    margin-top: auto;
  }
  .cor-chart-legend-scale {
    width: 237px;
    height: 8px;
    border-radius: 58px;
  }

  .no-score {
    background: #f9fcfc;
  }

  .legend-score {
    font-size: 16px;
    font-weight: 700;
    margin-left: 8px;
  }

  .cor-score-display-text {
    font-size: 20px;
    font-weight: 700;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }
}

.cor-score-radar-widget .cor-chart-legend-medium {
  margin-top: 56px;

  .cor-chart-legend-label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .cor-chart-legend-scale {
    width: 140px;
    height: 8px;
    border-radius: 58px;
  }

  .no-score {
    background: #f9fcfc;
  }

  .legend-score {
    font-size: 16px;
    font-weight: 700;
    margin-left: 8px;
  }
}
.score {
  display: inline-flex;

  p {
    opacity: 0.8;
    font-size: 16px;
    line-height: 1.75em;
  }

  h4 {
    font-weight: 700;
    line-height: 1em;
  }
}

.score-small {
  text-align: center;
  display: inline-flex;

  h5 {
    font-weight: 700;
    font-size: 20px;
  }
}

.chart-label-wrapper {
  text-align: center;
  .chart-label {
    font-size: 20px;
    font-weight: 700;
  }

  .chart-label-small {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0px;
  }
}
.top-label {
  margin-bottom: 44px;
}

.top-label-medium {
  margin-bottom: 20px;
}

.top-label-small {
  margin-bottom: 10px;
}

.dark-text {
  color: #46535e;
}

.light-text {
  color: $white;
}

.medium-border {
  border: 1px solid #cddbde;
}

@media (min-width: 613px) and (max-width: 1300px) {
  .cor-score-radar-widget {
    .cor-chart-legend {
      margin-right: auto;
      margin-left: auto;

      .cor-score-display-text {
        font-size: 12px;
      }

      .cor-score-display {
        font-size: 12px;
        height: 34px;
        width: 36px;
      }

      .row {
        margin-right: 8px;
      }

      .row:last-of-type {
        margin-bottom: 12px;
      }
    }

    .graph-wrapper {
      margin-left: auto;
    }

    .cor-chart-legend-scale {
      width: 140px;
    }

    .cor-chart-legend-small .cor-chart-legend-scale {
      width: 100px;
    }
  }
}

@media (min-width: 960px) and (max-width: 1088px) {
  .cor-score-radar-widget {
    .cor-chart-legend {
      margin-top: 0px;
    }
  }
}

@media (max-width: 612px) {
  .cor-score-radar-widget {
    .cor-chart-legend {
      margin-right: auto;
      margin-left: auto;
      margin-top: 0px;

      .row:last-of-type {
        margin-bottom: 12px;
      }
    }

    .graph-wrapper {
      margin-left: auto;
    }

    .cor-chart-legend-small .cor-chart-legend-scale {
      width: 100px;
    }
  }
}

@media (max-width: 1152px) {
  .cor-score-radar-widget {
    .graph-wrapper {
      margin-left: auto !important;
    }
  }
}

@media (min-width: 960px) {
  .small-widget {
    .cor-chart-legend-small .cor-chart-legend-scale {
      width: 150px;
    }
    .graph-wrapper {
      margin-left: 0px !important;
    }

    .cor-chart-legend-small {
      margin-right: 30px !important;
    }
  }
}

@media (max-width: 959px) {
  .small-widget {
    .cor-chart-legend-small .cor-chart-legend-scale {
      width: 150px;
    }
    .graph-wrapper {
      margin-left: 0px !important;
    }

    .cor-chart-legend-small {
      margin-right: 30px !important;
    }
  }
}
</style>
