<template>
  <div class="definition-tooltip d-inline">
    <v-menu
      ref="tooltipCard"
      v-model="tooltipOpen"
      :close-on-content-click="false"
      :close-delay="500"
      :bottom="bottom"
      :top="!bottom"
      content-class="tooltip-card"
      nudge-left="176"
      nudge-top="16"
      open-on-hover
      offset-y
      eager
      @input="handleOpened"
    >
      <template #activator="{ on, attrs }">
        <div class="d-inline" v-bind="attrs" v-on="on">
          <span class="fa-stack">
            <i v-if="!white" class="fas fa-circle fa-stack-1x white--text" />
            <i
              ref="activator"
              class="fas fa-info-circle fa-stack-1x"
              :class="white ? 'white--text' : 'primary--text'"
            />
          </span>
        </div>
      </template>
      <v-card outlined class="definition-tooltip-card">
        <v-card-title class="align-content-start flex-column">
          <div class="caption w100">
            {{ $t('definitionTooltips.metric') }}
          </div>
          <div class="w100">{{ title }}</div>
        </v-card-title>
        <v-card-text>
          <div>
            {{ definition }}
          </div>
          <div class="mt-4">
            <a class="link" @click="dialogOpen = true">
              {{ $t('definitionTooltips.more') }}
            </a>
            <i class="fas fa-external-link primary--text" />
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-dialog v-model="dialogOpen" max-width="500">
      <v-card>
        <v-card-title class="justify-space-between">
          <div>
            <div class="caption">
              {{ $t('definitionTooltips.metric') }}
            </div>
            {{ title }}
          </div>
          <v-btn icon @click="dialogOpen = false">
            <i class="far fa-times" />
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="subtitle-2 font-weight-bold">
            {{ $t('definitionTooltips.definition') }}
          </div>
          <p>{{ definition }}</p>
          <div class="subtitle-2 mt-4 font-weight-bold">
            {{ $t('definitionTooltips.why') }}
          </div>
          <p>{{ moreInfo }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DefinitionTooltip',
  props: {
    title: {
      type: String,
      required: true,
    },
    definition: {
      type: String,
      required: true,
    },
    moreInfo: {
      type: String,
      required: true,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tooltipOpen: false,
      dialogOpen: false,
    };
  },
  methods: {
    // This function repositions the tooltip arrow to align with the activator icon when the tooltip is hitting the edge of the screen
    handleOpened(value) {
      this.$nextTick(() => {
        // This delay is needed because there is an extremely slight delay between this function running and the tooltip being rendered
        setTimeout(() => {
          if (value) {
            const activator = this.$refs.activator.getBoundingClientRect().left;
            const tooltip = this.$refs.tooltipCard.$children[0].$el;
            const tooltipLeft = tooltip.getBoundingClientRect().left;
            const left = activator - tooltipLeft - 6;
            tooltip.style.setProperty('--left-offset', left + 'px');
            if (this.bottom) {
              tooltip.classList.add('tooltip-card__arrow-top');
            } else {
              tooltip.classList.add('tooltip-card__arrow-bottom');
            }
          }
        }, 50);
      });
    },
  },
};
</script>

<style lang="scss">
.tooltip-card {
  --left-offset: 170px;

  max-width: 350px;
  contain: initial;
  overflow: visible;

  &__arrow-bottom {
    margin-bottom: 25px !important;
    &::before {
      position: absolute;
      content: '';
      bottom: 1px;
      left: var(--left-offset);
      transform: translateY(100%);
      width: 10px;
      height: 12px;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid #fff;
      z-index: 1;
    }
  }
  &__arrow-top {
    margin-top: 25px !important;
    &::before {
      position: absolute;
      content: '';
      top: 1px;
      left: var(--left-offset);
      transform: translateY(-100%);
      width: 10px;
      height: 12px;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid #fff;
      z-index: 1;
    }
  }
  .definition-tooltip-card {
    max-width: 350px;
  }
  .w100 {
    width: 100%;
  }
}
.fa-stack {
  line-height: 1em;
  height: 1.1em;
  width: 1em;
}
</style>
