var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cor-score-radar-widget row",class:[
    _vm.graphSize === 'normal' ? 'ma-0 pt-8 mb-8' : '',
    _vm.themeColor === 'dark'
      ? 'dark-background'
      : _vm.themeColor === 'medium'
      ? 'medium-background'
      : 'light-background',
    _vm.graphSize === 'small' ? 'small-widget' : '' ]},[(_vm.noData)?_c('NoData',{staticClass:"no-data"}):_vm._e(),(_vm.graphSize === 'small')?_c('div',{staticClass:"d-flex flex-column mt-4 ml-5"},[_c('p',{staticClass:"cor-score-small mb-1",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.corScore"))+" "),_c('span',{staticClass:"trade-mark pr-1"},[_vm._v(_vm._s(_vm.$t("corScoreRadarWidget.labels.tradeMark")))]),_c('DefinitionTooltip',{attrs:{"title":_vm.$t('definitionTooltips.cor.title'),"definition":_vm.$t('definitionTooltips.cor.definition'),"more-info":_vm.$t('definitionTooltips.cor.more'),"white":_vm.themeColor === 'dark',"bottom":""}})],1),_c('span',{staticClass:"cor-score-display-small",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(_vm._s(_vm.corScoreValue(_vm.corScore.overallScore)))])]):_vm._e(),(_vm.graphSize === 'medium')?_c('div',{staticClass:"row mt-5 ml-0 mr-0 pb-5 medium-header"},[_c('div',{staticClass:"d-flex pt-0 ml-auto"},[_c('p',{staticClass:"cor-score-medium",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.corScore"))+" "),_c('span',{staticClass:"trade-mark"},[_vm._v(_vm._s(_vm.$t("corScoreRadarWidget.labels.tradeMark")))]),_c('DefinitionTooltip',{attrs:{"title":_vm.$t('definitionTooltips.cor.title'),"definition":_vm.$t('definitionTooltips.cor.definition'),"more-info":_vm.$t('definitionTooltips.cor.more'),"white":_vm.themeColor === 'dark',"bottom":""}})],1)]),_c('div',{staticClass:"d-flex pt-0 ml-auto mr-auto"},[_c('span',{staticClass:"cor-score-display-text-medium"},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.corScoreDisplayText"))+" ")]),_c('div',{staticClass:"cor-score-display-medium"},[_vm._v(" "+_vm._s(_vm.corScoreValue(_vm.corScore.overallScore))+" ")])])]):_vm._e(),_c('div',{staticClass:"d-flex flex-column col-xs-6 graph-wrapper",class:[
      _vm.graphSize === 'normal'
        ? 'mr-auto ml-8'
        : _vm.graphSize === 'medium'
        ? 'mr-5 ml-5'
        : '' ]},[(!_vm.noData)?_c('div',{class:_vm.graphSize === 'normal'
          ? ''
          : _vm.graphSize === 'medium'
          ? 'mt-auto mb-auto'
          : ''},[(_vm.graphSize === 'normal')?_c('div',[_c('p',{staticClass:"cor-score",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.corScore"))+" "),_c('span',{staticClass:"trade-mark mr-1"},[_vm._v(_vm._s(_vm.$t("corScoreRadarWidget.labels.tradeMark")))]),_c('DefinitionTooltip',{attrs:{"title":_vm.$t('definitionTooltips.cor.title'),"definition":_vm.$t('definitionTooltips.cor.definition'),"more-info":_vm.$t('definitionTooltips.cor.more'),"white":_vm.themeColor === 'dark',"bottom":""}})],1)]):_vm._e()]):_vm._e(),_c('div',{class:_vm.graphSize === 'normal'
          ? 'mt-7 mb-5'
          : _vm.graphSize === 'small'
          ? ''
          : 'mt-7'},[_c('div',{staticClass:"chart-label-wrapper mx-auto",class:_vm.graphSize === 'normal'
            ? 'top-label'
            : _vm.graphSize === 'medium'
            ? 'top-label-medium'
            : 'top-label-small'},[_c('p',{class:[
            _vm.themeColor !== 'dark' ? 'dark-text' : 'light-text',
            _vm.graphSize === 'normal' ? 'chart-label' : 'chart-label-small' ]},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.opportunityAbbreviate"))+" ")])]),_c('canvas',{ref:"chart",class:_vm.graphSize === 'normal'
            ? 'canvas'
            : _vm.graphSize === 'small'
            ? 'canvasSmall'
            : 'canvasMedium'}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"chart-label-wrapper",class:_vm.graphSize === 'medium'
              ? 'mt-9 ml-5'
              : _vm.graphSize === 'normal'
              ? 'ml-4 mt-4'
              : 'ml-4'},[_c('p',{class:[
              _vm.themeColor !== 'dark' ? 'dark-text' : 'light-text',
              _vm.graphSize === 'normal' ? 'chart-label' : 'chart-label-small' ]},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.coverageAbbreviate"))+" ")])]),_c('div',{staticClass:"chart-label-wrapper",class:_vm.graphSize === 'medium'
              ? 'mt-9 mr-4'
              : _vm.graphSize === 'normal'
              ? 'mt-4'
              : 'mr-4'},[_c('p',{class:[
              _vm.themeColor !== 'dark' ? 'dark-text' : 'light-text',
              _vm.graphSize === 'normal' ? 'chart-label' : 'chart-label-small' ]},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.riskAbbreviate"))+" ")])])])])]),(_vm.graphSize === 'normal')?_c('div',{staticClass:"flex-column cor-chart-legend col-xs-6 mr-auto ml-auto mb-4"},[_c('div',{staticClass:"row"},[_c('p',{staticClass:"cor-chart-legend-label",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.coverage"))+" "),_c('DefinitionTooltip',{attrs:{"title":_vm.$t('definitionTooltips.coverage.title'),"definition":_vm.$t('definitionTooltips.coverage.definition'),"more-info":_vm.$t('definitionTooltips.coverage.more'),"white":_vm.themeColor === 'dark',"bottom":""}})],1)]),_c('div',{staticClass:"row mt-0"},[(_vm.corScoreValue(_vm.corScore.coverageScore))?_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto",class:_vm.themeColor === 'medium' ? 'medium-border' : '',style:({
          background: ("linear-gradient(to right,  " + _vm.corScoreColor + " 0%, " + _vm.corScoreColor + " " + (_vm.corScoreValue(
            _vm.corScore.coverageScore
          )) + "%, #F9FCFC " + (_vm.corScoreValue(
            _vm.corScore.coverageScore
          )) + "%, #F9FCFC 100%)"),
        })}):_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto no-score",class:_vm.themeColor === 'medium' ? 'medium-border' : ''}),_c('span',{staticClass:"legend-score",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(_vm._s(_vm.displayCorScore(_vm.corScore.coverageScore)))])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"cor-chart-legend-label",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.opportunity"))+" "),_c('DefinitionTooltip',{attrs:{"title":_vm.$t('definitionTooltips.opportunity.title'),"definition":_vm.$t('definitionTooltips.opportunity.definition'),"more-info":_vm.$t('definitionTooltips.opportunity.more'),"white":_vm.themeColor === 'dark',"bottom":""}})],1)]),_c('div',{staticClass:"row mt-0"},[(_vm.corScoreValue(_vm.corScore.opportunityScore))?_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto",class:_vm.themeColor === 'medium' ? 'medium-border' : '',style:({
          background: ("linear-gradient(to right,  " + _vm.opportunityScoreColor + " 0%, " + _vm.opportunityScoreColor + " " + (_vm.corScoreValue(
            _vm.corScore.opportunityScore
          )) + "%, #F9FCFC " + (_vm.corScoreValue(
            _vm.corScore.opportunityScore
          )) + "%, #F9FCFC 100%)"),
        })}):_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto no-score",class:_vm.themeColor === 'medium' ? 'medium-border' : ''}),_c('span',{staticClass:"legend-score",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(_vm._s(_vm.displayCorScore(_vm.corScore.opportunityScore)))])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"cor-chart-legend-label",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.risk"))+" "),_c('DefinitionTooltip',{attrs:{"title":_vm.$t('definitionTooltips.risk.title'),"definition":_vm.$t('definitionTooltips.risk.definition'),"more-info":_vm.$t('definitionTooltips.risk.more'),"white":_vm.themeColor === 'dark',"bottom":""}})],1)]),_c('div',{staticClass:"row mt-0"},[(_vm.corScoreValue(_vm.corScore.riskScore))?_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto",class:_vm.themeColor === 'medium' ? 'medium-border' : '',style:({
          background: ("linear-gradient(to right,  " + _vm.riskScoreColor + " 0%, " + _vm.riskScoreColor + " " + (_vm.corScoreValue(
            _vm.corScore.riskScore
          )) + "%, #F9FCFC " + (_vm.corScoreValue(_vm.corScore.riskScore)) + "%, #F9FCFC 100%)"),
        })}):_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto no-score",class:_vm.themeColor === 'medium' ? 'medium-border' : ''}),_c('span',{staticClass:"legend-score",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(_vm._s(_vm.displayCorScore(_vm.corScore.riskScore)))])]),_c('div',{staticClass:"row mt-8"},[_c('span',{staticClass:"cor-score-display-text",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.corScoreDisplayText"))+" ")]),_c('div',{staticClass:"cor-score-display",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(" "+_vm._s(_vm.corScoreValue(_vm.corScore.overallScore))+" ")])])]):_vm._e(),(_vm.graphSize === 'small')?_c('div',{staticClass:"flex-column cor-chart-legend-small mr-5 mt-1 ml-auto"},[_c('div',{staticClass:"row mt-0 mb-0"},[_c('p',{staticClass:"cor-chart-legend-label mr-auto",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.coverage"))+" "),_c('DefinitionTooltip',{attrs:{"title":_vm.$t('definitionTooltips.coverage.title'),"definition":_vm.$t('definitionTooltips.coverage.definition'),"more-info":_vm.$t('definitionTooltips.coverage.more'),"white":_vm.themeColor === 'dark',"bottom":""}})],1),(_vm.corScoreValue(_vm.corScore.coverageScore))?_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto",class:_vm.themeColor === 'medium' ? 'medium-border' : '',style:({
          background: ("linear-gradient(to right,  " + _vm.corScoreColor + " 0%, " + _vm.corScoreColor + " " + (_vm.corScoreValue(
            _vm.corScore.coverageScore
          )) + "%, #F9FCFC " + (_vm.corScoreValue(
            _vm.corScore.coverageScore
          )) + "%, #F9FCFC 100%)"),
        })}):_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto no-score",class:_vm.themeColor === 'medium' ? 'medium-border' : ''}),_c('span',{staticClass:"legend-score",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(_vm._s(_vm.displayCorScore(_vm.corScore.coverageScore)))])]),_c('div',{staticClass:"row mt-0 mb-0"},[_c('p',{staticClass:"cor-chart-legend-label mr-auto",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.opportunity"))+" "),_c('DefinitionTooltip',{attrs:{"title":_vm.$t('definitionTooltips.opportunity.title'),"definition":_vm.$t('definitionTooltips.opportunity.definition'),"more-info":_vm.$t('definitionTooltips.opportunity.more'),"white":_vm.themeColor === 'dark',"bottom":""}})],1),(_vm.corScoreValue(_vm.corScore.opportunityScore))?_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto",class:_vm.themeColor === 'medium' ? 'medium-border' : '',style:({
          background: ("linear-gradient(to right,  " + _vm.opportunityScoreColor + " 0%, " + _vm.opportunityScoreColor + " " + (_vm.corScoreValue(
            _vm.corScore.opportunityScore
          )) + "%, #F9FCFC " + (_vm.corScoreValue(
            _vm.corScore.opportunityScore
          )) + "%, #F9FCFC 100%)"),
        })}):_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto no-score",class:_vm.themeColor === 'medium' ? 'medium-border' : ''}),_c('span',{staticClass:"legend-score",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(_vm._s(_vm.displayCorScore(_vm.corScore.opportunityScore)))])]),_c('div',{staticClass:"row mt-0 mb-0"},[_c('p',{staticClass:"cor-chart-legend-label mr-auto",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.risk"))+" "),_c('DefinitionTooltip',{attrs:{"title":_vm.$t('definitionTooltips.risk.title'),"definition":_vm.$t('definitionTooltips.risk.definition'),"more-info":_vm.$t('definitionTooltips.risk.more'),"white":_vm.themeColor === 'dark',"bottom":""}})],1),(_vm.corScoreValue(_vm.corScore.riskScore))?_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto",class:_vm.themeColor === 'medium' ? 'medium-border' : '',style:({
          background: ("linear-gradient(to right,  " + _vm.riskScoreColor + " 0%, " + _vm.riskScoreColor + " " + (_vm.corScoreValue(
            _vm.corScore.riskScore
          )) + "%, #F9FCFC " + (_vm.corScoreValue(_vm.corScore.riskScore)) + "%, #F9FCFC 100%)"),
        })}):_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto no-score",class:_vm.themeColor === 'medium' ? 'medium-border' : ''}),_c('span',{staticClass:"legend-score",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(_vm._s(_vm.displayCorScore(_vm.corScore.riskScore)))])])]):_vm._e(),(_vm.graphSize === 'medium')?_c('div',{staticClass:"flex-column cor-chart-legend-medium mr-auto"},[_c('div',{staticClass:"row"},[_c('p',{staticClass:"cor-chart-legend-label",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.coverage"))+" "),_c('DefinitionTooltip',{attrs:{"title":_vm.$t('definitionTooltips.coverage.title'),"definition":_vm.$t('definitionTooltips.coverage.definition'),"more-info":_vm.$t('definitionTooltips.coverage.more'),"white":_vm.themeColor === 'dark',"bottom":""}})],1)]),_c('div',{staticClass:"row mt-0"},[(_vm.corScoreValue(_vm.corScore.coverageScore))?_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto",class:_vm.themeColor === 'medium' ? 'medium-border' : '',style:({
          background: ("linear-gradient(to right,  " + _vm.corScoreColor + " 0%, " + _vm.corScoreColor + " " + (_vm.corScoreValue(
            _vm.corScore.coverageScore
          )) + "%, #F9FCFC " + (_vm.corScoreValue(
            _vm.corScore.coverageScore
          )) + "%, #F9FCFC 100%)"),
        })}):_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto no-score",class:_vm.themeColor === 'medium' ? 'medium-border' : ''}),_c('span',{staticClass:"legend-score",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(_vm._s(_vm.displayCorScore(_vm.corScore.coverageScore)))])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"cor-chart-legend-label",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.opportunity"))+" "),_c('DefinitionTooltip',{attrs:{"title":_vm.$t('definitionTooltips.opportunity.title'),"definition":_vm.$t('definitionTooltips.opportunity.definition'),"more-info":_vm.$t('definitionTooltips.opportunity.more'),"white":_vm.themeColor === 'dark',"bottom":""}})],1)]),_c('div',{staticClass:"row mt-0"},[(_vm.corScoreValue(_vm.corScore.opportunityScore))?_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto",class:_vm.themeColor === 'medium' ? 'medium-border' : '',style:({
          background: ("linear-gradient(to right,  " + _vm.opportunityScoreColor + " 0%, " + _vm.opportunityScoreColor + " " + (_vm.corScoreValue(
            _vm.corScore.opportunityScore
          )) + "%, #F9FCFC " + (_vm.corScoreValue(
            _vm.corScore.opportunityScore
          )) + "%, #F9FCFC 100%)"),
        })}):_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto no-score",class:_vm.themeColor === 'medium' ? 'medium-border' : ''}),_c('span',{staticClass:"legend-score",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(_vm._s(_vm.displayCorScore(_vm.corScore.opportunityScore)))])]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"cor-chart-legend-label",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(" "+_vm._s(_vm.$t("corScoreRadarWidget.labels.risk"))+" "),_c('DefinitionTooltip',{attrs:{"title":_vm.$t('definitionTooltips.risk.title'),"definition":_vm.$t('definitionTooltips.risk.definition'),"more-info":_vm.$t('definitionTooltips.risk.more'),"white":_vm.themeColor === 'dark',"bottom":""}})],1)]),_c('div',{staticClass:"row mt-0"},[(_vm.corScoreValue(_vm.corScore.riskScore))?_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto",class:_vm.themeColor === 'medium' ? 'medium-border' : '',style:({
          background: ("linear-gradient(to right,  " + _vm.riskScoreColor + " 0%, " + _vm.riskScoreColor + " " + (_vm.corScoreValue(
            _vm.corScore.riskScore
          )) + "%, #F9FCFC " + (_vm.corScoreValue(_vm.corScore.riskScore)) + "%, #F9FCFC 100%)"),
        })}):_c('div',{staticClass:"cor-chart-legend-scale mb-auto mt-auto no-score",class:_vm.themeColor === 'medium' ? 'medium-border' : ''}),_c('span',{staticClass:"legend-score",class:_vm.themeColor !== 'dark' ? 'dark-text' : 'light-text'},[_vm._v(_vm._s(_vm.displayCorScore(_vm.corScore.riskScore)))])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }