<template>
  <div class="metrics-form">
    <v-sheet class="elevation-2 pa-6">
      <div v-if="loading" class="entity-loading">
        <Spinner class="absolute-center" light />
      </div>
      <template v-else>
        <div v-show="tab === 0" class="creditMetrics">
          <h5 class="mb-2">Credit Metrics</h5>
          <slot name="credit-summary"></slot>
          <div v-for="(metric, i) in creditMetrics" :key="`credit_metric_${i}`">
            <span class="metric-title">
              {{ $t(`cpiMetricQuestions.creditMetrics.${metric.value}.text`) }}
            </span>
            <v-radio-group
              v-model="creditAnswers[`${metric.value}`]"
              class="mt-0"
              @change="calculateCreditScore"
            >
              <v-radio
                v-for="(question, k) in metric.questions"
                :key="`question_${i}_${k}`"
                :label="
                  $t(
                    `cpiMetricQuestions.creditMetrics.${metric.value}.questions.${question.value}`
                  )
                "
                :value="question.value"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
        <div v-show="tab === 1" class="collateralMetrics">
          <h5 class="mb-2">Collateral Metrics</h5>
          <slot name="collateral-summary"></slot>
          <div
            v-for="(metric, i) in collateralMetrics"
            :key="`collateral_metric_${i}`"
          >
            <span class="metric-title">
              {{
                $t(`cpiMetricQuestions.collateralMetrics.${metric.value}.text`)
              }}
            </span>
            <v-radio-group
              v-model="collateralAnswers[`${metric.value}`]"
              class="mt-0"
              @change="calculateCollateralScore"
            >
              <v-radio
                v-for="(question, k) in metric.questions"
                :key="`question_${i}_${k}`"
                :label="
                  $t(
                    `cpiMetricQuestions.collateralMetrics.${metric.value}.questions.${question.value}`
                  )
                "
                :value="question.value"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
      </template>
    </v-sheet>
  </div>
</template>

<script>
import { getCollateralAnswers, getCreditAnswers } from '@/lib/helpers';

export default {
  name: 'MetricsForm',
  props: {
    tab: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      creditMetrics: [
        {
          value: 'ltm_rev',
          questions: [
            {
              value: 'gt',
              score: 5.0,
            },
            {
              value: 'bt',
              score: 3.0,
            },
            {
              value: 'lt',
              score: 1.0,
            },
          ],
        },
        {
          value: 'rev_vis',
          questions: [
            {
              value: 'gt',
              score: 3.0,
            },
            {
              value: 'bt',
              score: 2.0,
            },
            {
              value: 'lt',
              score: 1.0,
            },
          ],
        },
        {
          value: 'ltm_net_rev_loan',
          questions: [
            {
              value: 'two_to_one',
              score: 3.0,
            },
            {
              value: 'one_to_one',
              score: 2.0,
            },
            {
              value: 'lt_one',
              score: 1.0,
            },
          ],
        },
        {
          value: 'twelve_month_net_rev_loan',
          questions: [
            {
              value: 'gt',
              score: 3.0,
            },
            {
              value: 'bt',
              score: 2.0,
            },
            {
              value: 'lt',
              score: 1.0,
            },
          ],
        },
        {
          value: 'ltm_ebitda',
          questions: [
            {
              value: 'gt',
              score: 3.0,
            },
            {
              value: 'bt',
              score: 2.0,
            },
            {
              value: 'lt',
              score: 1.0,
            },
          ],
        },
        {
          value: 'next_year_ebitda',
          questions: [
            {
              value: 'lt',
              score: 5.0,
            },
            {
              value: 'bt_10_to_5',
              score: 4.0,
            },
            {
              value: 'bt_20_to_11',
              score: 3.0,
            },
            {
              value: 'gt',
              score: 2.0,
            },
            {
              value: 'na',
              score: 1.0,
            },
          ],
        },
        {
          value: 'loan_ent_score',
          questions: [
            {
              value: 'lt',
              score: 5.0,
            },
            {
              value: 'bt',
              score: 3.0,
            },
            {
              value: 'gt',
              score: 1.0,
            },
          ],
        },
        {
          value: 'loan_inv_capital',
          questions: [
            {
              value: 'lt',
              score: 5.0,
            },
            {
              value: 'bt',
              score: 3.0,
            },
            {
              value: 'gt',
              score: 1.0,
            },
          ],
        },
        {
          value: 'quality_equity_backers',
          questions: [
            {
              value: 'gt',
              score: 5.0,
            },
            {
              value: 'bt',
              score: 3.0,
            },
            {
              value: 'lt',
              score: 1.0,
            },
          ],
        },
        {
          value: 'refinance_need',
          questions: [
            {
              value: 'likely',
              score: 5.0,
            },
            {
              value: 'potential',
              score: 3.0,
            },
            {
              value: 'unlikely',
              score: 1.0,
            },
          ],
        },
        {
          value: 'management_team',
          questions: [
            {
              value: 'full',
              score: 5.0,
            },
            {
              value: 'not_full',
              score: 3.0,
            },
            {
              value: 'thin',
              score: 1.0,
            },
          ],
        },
        {
          value: 'exit_paths',
          questions: [
            {
              value: 'ten_plus',
              score: 5.0,
            },
            {
              value: 'five_to_ten',
              score: 3.0,
            },
            {
              value: 'one_to_five',
              score: 1.0,
            },
          ],
        },
        {
          value: 'tam_size',
          questions: [
            {
              value: 'lt',
              score: 3.0,
            },
            {
              value: 'bt',
              score: 2.0,
            },
            {
              value: 'gt',
              score: 1.0,
            },
          ],
        },
        {
          value: 'tam_growth',
          questions: [
            {
              value: 'gt',
              score: 3.0,
            },
            {
              value: 'bt',
              score: 2.0,
            },
            {
              value: 'lt',
              score: 1.0,
            },
          ],
        },
        {
          value: 'easy_capital',
          questions: [
            {
              value: 'public',
              score: 3.0,
            },
            {
              value: 'private',
              score: 2.0,
            },
            {
              value: 'none',
              score: 1.0,
            },
          ],
        },
        {
          value: 'debt_service',
          questions: [
            {
              value: 'yes',
              score: 1.0,
            },
            {
              value: 'none',
              score: 0.0,
            },
            {
              value: 'default',
              score: -1.0,
            },
          ],
        },
        {
          value: 'business_plan',
          questions: [
            {
              value: 'ff_high',
              score: 4.0,
            },
            {
              value: 'ff_mid',
              score: 3.0,
            },
            {
              value: 'add_cap_req',
              score: 1.0,
            },
          ],
        },
        {
          value: 'customer_retention',
          questions: [
            {
              value: 'high',
              score: 3.0,
            },
            {
              value: 'some',
              score: 2.0,
            },
            {
              value: 'limited',
              score: 1.0,
            },
          ],
        },
        {
          value: 'customer_acq_costs',
          questions: [
            {
              value: 'low',
              score: 2.0,
            },
            {
              value: 'mid',
              score: 1.0,
            },
            {
              value: 'high',
              score: -1.0,
            },
          ],
        },
        {
          value: 'sales_channel_maturity',
          questions: [
            {
              value: 'high',
              score: 3.0,
            },
            {
              value: 'med',
              score: 2.0,
            },
            {
              value: 'low',
              score: 1.0,
            },
          ],
        },
        {
          value: 'historical_rev_cagr',
          questions: [
            {
              value: 'gt',
              score: 2.0,
            },
            {
              value: 'bt',
              score: 1.0,
            },
            {
              value: 'lt',
              score: 0.5,
            },
          ],
        },
        {
          value: 'refinancing',
          questions: [
            {
              value: 'none',
              score: 2.0,
            },
            {
              value: 'lt',
              score: 1.0,
            },
            {
              value: 'gt',
              score: 0.5,
            },
          ],
        },
      ],
      collateralMetrics: [
        {
          value: 'protected_trade_secrets',
          questions: [
            {
              value: 'ts_strong',
              score: 3.0,
            },
            {
              value: 'ts_lim',
              score: 2.0,
            },
            {
              value: 'lim',
              score: 1.0,
            },
          ],
        },
        {
          value: 'loan_most_recent_ent_score',
          questions: [
            {
              value: 'lt',
              score: 5.0,
            },
            {
              value: 'bt',
              score: 3.0,
            },
            {
              value: 'gt',
              score: 1.0,
            },
          ],
        },
        {
          value: 'historical_rd_investment',
          questions: [
            {
              value: 'gt',
              score: 3.0,
            },
            {
              value: 'bt',
              score: 2.0,
            },
            {
              value: 'lt',
              score: 1.0,
            },
          ],
        },
        {
          value: 'ip_monetization',
          questions: [
            {
              value: 'mult_claim',
              score: 1.0,
            },
            {
              value: 'mult',
              score: 0.5,
            },
            {
              value: 'none',
              score: 0.0,
            },
          ],
        },
        {
          value: 'ip_software_licenses',
          questions: [
            {
              value: 'generating',
              score: 5.0,
            },
            {
              value: 'forward',
              score: 3.0,
            },
            {
              value: 'limited',
              score: 1.0,
            },
          ],
        },
        {
          value: 'hard_assets',
          questions: [
            {
              value: 'gt',
              score: 5.0,
            },
            {
              value: 'bt',
              score: 3.0,
            },
            {
              value: 'lt_w_appraisal',
              score: 2.0,
            },
            {
              value: 'lt',
              score: 1.0,
            },
          ],
        },
        {
          value: 'secondary_markets_hard_assets',
          questions: [
            {
              value: 'mult_industry',
              score: 1.0,
            },
            {
              value: 'mult_industry_infrequent',
              score: 0.5,
            },
            {
              value: 'one_industry',
              score: 0.0,
            },
          ],
        },
        {
          value: 'regulatory_moats',
          questions: [
            {
              value: 'high',
              score: 3.0,
            },
            {
              value: 'med',
              score: 2.0,
            },
            {
              value: 'low',
              score: 1.0,
            },
          ],
        },
        {
          value: 'brand_metrics',
          questions: [
            {
              value: 'strong',
              score: 3.0,
            },
            {
              value: 'some',
              score: 2.0,
            },
            {
              value: 'none',
              score: 1.0,
            },
          ],
        },
        {
          value: 'data_assets',
          questions: [
            {
              value: 'critical_easily_monetized',
              score: 2.0,
            },
            {
              value: 'critical_monetized',
              score: 1.0,
            },
            {
              value: 'important',
              score: 0.0,
            },
          ],
        },
        {
          value: 'prior_acquisition_offers',
          questions: [
            {
              value: 'three_plus',
              score: 3.0,
            },
            {
              value: 'one_plus',
              score: 2.0,
            },
            {
              value: 'none',
              score: 1.0,
            },
          ],
        },
        {
          value: 'transferable_agreements',
          questions: [
            {
              value: 'material',
              score: 3.0,
            },
            {
              value: 'some',
              score: 2.0,
            },
            {
              value: 'willing',
              score: 1.0,
            },
          ],
        },
        {
          value: 'cash',
          questions: [
            {
              value: 'reserved',
              score: 2.0,
            },
            {
              value: 'swept',
              score: 1.0,
            },
            {
              value: 'balance',
              score: 0.5,
            },
          ],
        },
        {
          value: 'cash_in_runoff',
          questions: [
            {
              value: 'five_year_plus',
              score: 1.0,
            },
            {
              value: 'five_year',
              score: 1.0,
            },
            {
              value: 'limited',
              score: 0.0,
            },
          ],
        },
        {
          value: 'loan_arr',
          questions: [
            {
              value: 'lt',
              score: 3.0,
            },
            {
              value: 'bt',
              score: 2.0,
            },
            {
              value: 'gt',
              score: 1.0,
            },
          ],
        },
      ],
      creditAnswers: null,
      collateralAnswers: null,
      loading: true,
    };
  },
  computed: {
    creditScore() {
      return this.calculateCreditScore();
    },
  },
  watch: {
    creditAnswers() {
      this.$emit('creditAnswersChanged', this.creditAnswers);
    },
    collateralAnswers() {
      this.$emit('collateralAnswersChanged', this.collateralAnswers);
    },
  },
  async created() {
    this.loading = true;
    this.creditAnswers = getCreditAnswers(this.$route.params.id);
    this.collateralAnswers = getCollateralAnswers(this.$route.params.id);
    this.loading = false;

    this.calculateCreditScore();
    this.calculateCollateralScore();
  },
  methods: {
    calculateCreditScore() {
      let creditTotal = 78;
      let creditScore = 0;
      this.creditMetrics.forEach(cq => {
        const ans = this.creditAnswers[`${cq.value}`];
        if (ans) {
          const question = cq.questions.find(q => q.value === ans);
          creditScore += question.score;
        }
      });
      let creditPerc = Math.floor((creditScore / creditTotal) * 100.0);
      this.$emit('creditScoreChanged', creditPerc);
      return creditPerc;
    },
    calculateCollateralScore() {
      let collateralTotal = 43;
      let collateralScore = 0;
      this.collateralMetrics.forEach(cq => {
        const ans = this.collateralAnswers[`${cq.value}`];
        if (ans) {
          const question = cq.questions.find(q => q.value === ans);
          collateralScore += question.score;
        }
      });
      let collateralPerc = Math.floor(
        (collateralScore / collateralTotal) * 100.0
      );
      this.$emit('collateralScoreChanged', collateralPerc);
      return collateralPerc;
    },
  },
};
</script>

<style lang="scss">
.metric-title {
  font-weight: 500;
  text-decoration: underline dotted;
}
.metrics-form {
  max-width: calc(100% - 800px);
}
.anchor-btn {
  text-decoration: none !important;
}
.anchor {
  display: block;
  position: relative;
  top: -75px;
  visibility: hidden;
}
</style>
