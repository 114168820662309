<template>
  <div class="mt-8">
    <div class="matrix-title mb-1">Deal Segmentation</div>
    <div id="chart" class="chart" />
    <div class="show-similar">
      <v-btn color="primary" small @click="showSimilar = true">
        Show Comparables
      </v-btn>
    </div>

    <v-dialog v-model="showSimilar" origin="top center" hide-overlay>
      <v-card class="similarity-matrix pa-0">
        <img src="@/assets/img/matrix-demo.png" alt="similar deals" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'SegmentationMatrix',
  props: {
    collateralScore: {
      type: Number,
      required: true,
    },
    creditScore: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      collateral: this.collateralScore,
      credit: this.creditScore,
      height: 400,
      width: 400,
      svg: null,
      x: null,
      y: null,
      tooltip: null,
      showSimilar: false,
    };
  },
  watch: {
    collateralScore() {
      this.refreshGraph();
    },
    creditScore() {
      this.refreshGraph();
    },
  },
  async mounted() {
    this.drawGraph();
  },
  methods: {
    refreshGraph() {
      this.svg.remove();
      this.addData();
    },
    drawGraph() {
      d3.select('#chart')
        .style('display', 'block')
        .style('margin-left', 'auto')
        .style('position', 'relative')
        .style('width', this.width + 'px')
        .style('height', this.height + 'px')
        .style('border', '1px solid #EEEEEE')
        .style('border-radius', '15px')
        .style('background-color', '#fff')
        .style('z-index', '100');
      this.addCornerText();
      this.addLabels();
      this.addTicks();
      this.addData();
    },
    addData() {
      this.svg = d3
        .select('#chart')
        .append('svg')
        .attr('width', this.width)
        .attr('height', this.height);

      let x = d3
        .scaleLinear()
        .domain([48, 102]) // this domain ensures the star doesn't leave the edge of the graph
        .range([0, this.width]);
      this.x = x;

      let y = d3
        .scaleLinear()
        .domain([48, 102]) // this domain ensures the star doesn't leave the edge of the graph
        .range([this.height, 0]);
      this.y = y;

      this.svg
        .append('line')
        .style('stroke', '#EEEEEE')
        .attr('x1', 0)
        .attr('x2', this.width)
        .attr('y1', this.height * 0.5)
        .attr('y2', this.height * 0.5);

      this.svg
        .append('line')
        .style('stroke', '#EEEEEE')
        .attr('x1', this.width * 0.5)
        .attr('x2', this.width * 0.5)
        .attr('y1', 0)
        .attr('y2', this.height);

      const drawStar = d3
        .symbol()
        .type(d3.symbolStar)
        .size(200);
      this.svg
        .append('g')
        .selectAll('dot')
        .data([{ x: this.collateral, y: this.credit }])
        .enter()
        .append('path')
        .attr('d', d => drawStar(d))
        .attr('transform', function(d) {
          return 'translate(' + [x(d.x), y(d.y)] + ')';
        })
        .style(
          'fill',
          this.collateral >= 75 && this.credit >= 75 ? '#ffa600' : '#69b3a2'
        )
        .style('opacity', '1');

      this.tooltip = d3
        .select('#chart')
        .append('div')
        .attr('class', 'tooltip');
    },
    addTicks() {
      d3.select('#chart')
        .append('div')
        .style('font-family', 'Helvetica Now Text')
        .style('font-size', '14px')
        .style('position', 'absolute')
        .style('left', '-90px')
        .style('top', '0px')
        .html('Exceptional');
      d3.select('#chart')
        .append('div')
        .style('font-family', 'Helvetica Now Text')
        .style('font-size', '14px')
        .style('position', 'absolute')
        .style('left', '-60px')
        .style('bottom', '0px')
        .html('Strong');
      d3.select('#chart')
        .append('div')
        .style('font-family', 'Helvetica Now Text')
        .style('font-size', '14px')
        .style('position', 'absolute')
        .style('left', '0px')
        .style('bottom', '-35px')
        .html('Strong');
      d3.select('#chart')
        .append('div')
        .style('font-family', 'Helvetica Now Text')
        .style('font-size', '14px')
        .style('position', 'absolute')
        .style('right', '0px')
        .style('bottom', '-35px')
        .html('Exceptional');
    },
    addLabels() {
      d3.select('#chart')
        .append('div')
        .style('font-family', 'Helvetica Now Text')
        .style('font-weight', '500')
        .style('font-size', '14px')
        .style('position', 'absolute')
        .style('text-decoration-style', 'dashed')
        .style('transform', 'rotate(-90deg)')
        .style('left', '-65px')
        .style('top', this.height / 2 - 10 + 'px')
        .html('Credit Quality');

      d3.select('#chart')
        .append('div')
        .style('font-family', 'Helvetica Now Text')
        .style('font-weight', '500')
        .style('font-size', '14px')
        .style('position', 'absolute')
        .style('text-decoration-style', 'dashed')
        .style('bottom', '-35px')
        .style('left', this.width / 2 - 100 + 'px')
        .html('Collateral Quality and Value');
    },
    addCornerText() {
      // TOP LEFT "Credit Star"
      d3.select('#chart')
        .append('div')
        .attr('class', 'corner-label')
        .style('position', 'absolute')
        .style('top', '10px')
        .style('left', '10px')
        .html('<b>Credit Star<i class="fas fa-info-circle ml-1"></i><b/>')
        .on('mouseover', this.showCreditStarTooltip)
        .on('mouseleave', this.mouseLeave);

      // TOP RIGHT "Super Star"
      d3.select('#chart')
        .append('div')
        .attr('class', 'corner-label')
        .style('position', 'absolute')
        .style('top', '10px')
        .style('right', '10px')
        .html('<b>Super Star<i class="fas fa-info-circle ml-1"></i><b/>')
        .on('mouseover', this.showSuperStarTooltip)
        .on('mouseleave', this.mouseLeave);

      // BOTTOM LEFT "Typical Borrower"
      d3.select('#chart')
        .append('div')
        .attr('class', 'corner-label')
        .style('position', 'absolute')
        .style('bottom', '10px')
        .style('left', '10px')
        .html('<b>Typical Borrower<i class="fas fa-info-circle ml-1"></i><b/>')
        .on('mouseover', this.showTypicalBorrowerTooltip)
        .on('mouseleave', this.mouseLeave);

      // BOTTOM RIGHT "Collateral Star"
      d3.select('#chart')
        .append('div')
        .attr('class', 'corner-label')
        .style('position', 'absolute')
        .style('bottom', '10px')
        .style('right', '10px')
        .html('<b>Collateral Star<i class="fas fa-info-circle ml-1"></i><b/>')
        .on('mouseover', this.showCollateralStarTooltip)
        .on('mouseleave', this.mouseLeave);
    },
    showCreditStarTooltip() {
      this.tooltip
        .style('opacity', 1)
        .style('z-index', '100')
        .html(
          `<div>
            <h6>Credit Star</h6>
            <ul>
              <li>Pay as you go structure</li>
              <li>5 -10% SIR or 15 – 20% coinsurance</li>
              <li>Tighter collateral requirements</li>
              <li>Partial offset considerations</li>
              <li>Funding in 45 days</li>
            </ul>
          </div>`
        )
        .style('top', '48px')
        .style('right', 'unset')
        .style('bottom', 'unset')
        .style('left', '10px');
    },
    showSuperStarTooltip() {
      this.tooltip
        .style('opacity', 1)
        .style('z-index', '100')
        .html(
          `<div>
            <h6>Super Star</h6>
            <ul>
              <li>Pay as you go structure</li>
              <li>Less need for SPV</li>
              <li>5% SIR or 10% coinsurance</li>
              <li>Structure lowest cost of funds as possible</li>
              <li>Execution timeline in 30 - 45 days</li>
              <li>Lower cash sweep or none interplay with offset</li>
              <li>Looser covenants</li>
            </ul>
          </div>`
        )
        .style('top', '48px')
        .style('right', '10px')
        .style('bottom', 'unset')
        .style('left', '10px');
    },
    showTypicalBorrowerTooltip() {
      this.tooltip
        .style('opacity', 1)
        .style('z-index', '100')
        .html(
          `<div>
            <h6>Typical Borrower</h6>
            <ul>
              <li>Upfront Premium or guarantee by lender via full offset</li>
              <li>Tighter covenants</li>
              <li>10% SIR or 20%+ coinsurance</li>
              <li>Need for full trade secret escrow</li>
              <li>Longer marketing periods</li>
              <li>Transferable collateral</li>
            </ul>
          </div>`
        )
        .style('top', 'unset')
        .style('right', '10px')
        .style('bottom', '48px')
        .style('left', '10px');
    },
    showCollateralStarTooltip() {
      this.tooltip
        .style('opacity', 1)
        .style('z-index', '100')
        .html(
          `<div>
            <h6>Collateral Star</h6>
            <ul>
              <li>Upfront premium or offset considerations</li>
              <li>5 -10% SIR or 15 – 20% coinsurance</li>
              <li>Tight collateral covenants</li>
              <li>Growth covenants</li>
              <li>Transferable collateral</li>
              <li>Funding in 45 – 60 days</li>
            </ul>
          </div>`
        )
        .style('top', 'unset')
        .style('right', '10px')
        .style('bottom', '48px')
        .style('left', 'unset');
    },
    mouseLeave() {
      this.tooltip
        .transition()
        .duration(200)
        .style('z-index', '-1')
        .style('opacity', 0);
    },
  },
};
</script>

<style lang="scss">
.matrix-title {
  text-align: center;
  font-family: 'Helvetica Now Text', 'sans-serif';
  font-size: 16px;
  font-weight: bold;
}
.corner-label {
  font-size: 12px;
  font-weight: 400;
  padding: 2px 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.5px 2.5px 8px;
  border: 1px transparent;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.25);
  cursor: pointer;
}
.tooltip {
  opacity: 0;
  position: absolute;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.5px 2.5px 8px;
}
.show-similar {
  position: absolute;
  bottom: -90px;
  width: 400px;
  text-align: center;
}
.similarity-matrix {
  height: 540px;
  width: 579px;
  overflow: hidden;
}
</style>
