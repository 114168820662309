<template>
  <div class="entity-header" :class="collapsed ? 'collapsed' : ''">
    <div class="entity-header__container">
      <v-row>
        <div v-if="loading" class="entity-loading">
          <Spinner class="absolute-center" light />
        </div>
        <template v-else>
          <v-col :md="collapsed ? 4 : 5" lg="6" sm="12">
            <v-row dense>
              <v-col>
                <AppButton
                  prepend-icon="fas fa-chevron-left fa-xs"
                  dense
                  variation="ghost"
                  class="back-button white--text"
                  @click="backToList"
                >
                  {{ $t('cpiOpportunity.back') }}
                </AppButton>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col sm="8" class="d-flex mb-0" style="height: 100%">
                <EntityTitle v-if="entity" :entity="entity" dark />
              </v-col>
            </v-row>
            <v-row v-show="!collapsed" class="mt-5" dense>
              <v-col>
                <span v-if="entity.entity_type" class="companyMetaData pr-2">
                  <i class="fas fa-flag fa-md fa-fw"> </i>
                  <span>{{ entity.entity_type }}</span>
                </span>
                <span v-if="entity.address" class="companyMetaData px-2">
                  <i class="fas fa-map-marker-alt fa-md fa-fw"></i>
                  <span>{{ entity.address }}</span>
                </span>
              </v-col>
            </v-row>
            <div v-show="!collapsed" class="entity-details mt-5">
              <v-container fluid>
                <v-row class="mt-3">
                  <Description
                    :title="entity.name"
                    :description="entity.description"
                  />
                </v-row>
                <v-row class="mt-10">
                  <v-col class="d-flex pa-0">
                    <div
                      v-for="metric in companyMetric"
                      :key="metric"
                      class="companyMetrics mr-10"
                    >
                      <h4 class="mb-0">
                        <span class="mr-1">
                          {{ $t(`entityHeader.companyMetrics.${metric}`) }}
                        </span>
                        <DefinitionTooltip
                          :title="$t(`definitionTooltips.${map(metric)}.title`)"
                          :definition="
                            $t(`definitionTooltips.${map(metric)}.definition`)
                          "
                          :more-info="
                            $t(`definitionTooltips.${map(metric)}.more`)
                          "
                          white
                        />
                      </h4>
                      <h2>{{ getCompanyMetricValue(metric) }}</h2>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
          <v-col :md="collapsed ? 8 : 6" lg="6" sm="12">
            <CorScoreRadarWidget
              :key="collapsed"
              :cor-score="corScore"
              theme-color="dark"
              :graph-size="collapsed ? 'small' : 'normal'"
            ></CorScoreRadarWidget>
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>

<script>
import NumberAbbreviate from 'number-abbreviate';
import CorScoreRadarWidget from '@/components/IPAlphaComponents/CorScoreRadarWidget';
import EntityTitle from '@/components/IPAlphaComponents/EntityTitle';
import Description from '@/components/IPAlphaComponents/Description';
import DefinitionTooltip from '@/components/IPAlphaComponents/DefinitionTooltip';

const numberAbbreviate = new NumberAbbreviate(['K', 'M', 'B', 'T']);

export default {
  name: 'EntityHeader',
  components: {
    DefinitionTooltip,
    Description,
    CorScoreRadarWidget,
    EntityTitle,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: Object,
      default: null,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      companyMetric: ['power_score', 'diversity_score', 'velocity_score'],
      showCompanyHierarchy: false,
    };
  },
  computed: {
    totalRevenue() {
      return !this.entity || !this.entity.total_revenue
        ? null
        : `$${numberAbbreviate.abbreviate(
            parseInt(this.entity.total_revenue)
          )}`;
    },
    revenueYear() {
      return !this.entity
        ? null
        : `(${this.$moment.utc(this.entity.revenue_end_date).format('YYYY')})`;
    },
    corScore() {
      if (!this.entity) {
        return {
          overallScore: 0.0,
          coverageScore: 0.0,
          opportunityScore: 0.0,
          riskScore: 0.0,
        };
      }
      return {
        overallScore: this.entity.overall_score,
        coverageScore: this.entity.coverage_score,
        opportunityScore: this.entity.opportunity_score,
        riskScore: this.entity.risk_score,
      };
    },
    parentEntityId() {
      return this.entity.ultimate_aon_entity_pk
        ? parseInt(this.entity.ultimate_aon_entity_pk)
        : parseInt(this.entity.aon_entity_pk);
    },
  },
  methods: {
    backToList() {
      this.$router.push(`/cpi-opportunities-v2`);
    },
    getCompanyMetricValue(m) {
      const metric =
        m === 'diversity_score'
          ? this.entity[`final_${m}`]
          : this.entity[`${m}`];
      if (metric > 0 && metric < 1) {
        return '< 1';
      }
      const score = parseInt(metric);
      return score && score > 0 ? Math.floor(score) : 'N/A';
    },
    map(metric) {
      switch (metric) {
        case 'power_score':
          return 'power';
        case 'diversity_score':
          return 'diversity';
        case 'velocity_score':
          return 'velocity';
        case 'r&d_spend':
        case 'p/e':
        case 'market_cap':
          return 'N/A';
      }
    },
  },
};
</script>

<style lang="scss">
.i {
  pointer-events: all;
}
.aon {
  .collapsed {
    height: 126px !important;
    overflow-y: hidden !important;
    transition: height 0.25s linear;
  }
  .entity-header {
    position: sticky;
    top: 64px;
    overflow-anchor: none;
    overflow: hidden;
    z-index: 100;

    font-family: 'Roboto', sans-serif;
    color: #fff;
    background: #003c55;
    padding: 2.25em 40px 0 40px;
    height: 445px;
    transition: height 0.25s linear;

    &__container {
      position: relative;
    }

    .entity-address {
      flex-wrap: nowrap;
    }

    .graph-column {
      text-align: center;

      .graph-wrap {
        position: relative;
        display: inline-block;
        margin: 0 auto 0;
        text-align: center;
      }

      h4 {
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 400;

        strong {
          font-weight: 600;
        }
      }
    }

    .metrics-column {
      white-space: nowrap;

      .entity-name {
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        min-height: 66px;
        margin: 20px 0 15px;
        padding: 0 7em 0 0;
        font-weight: 300;

        .entity-parent-link {
          font-family: 'Roboto', sans-serif;
          font-size: 12px;
          padding: 0 0 0.25em 0;
          color: #fff;
          text-decoration: none;
        }
      }

      .entity-revenue {
        margin: 1em 4em 0 auto;

        > div {
          font-size: 12px;
        }
      }

      .factset-badge {
        display: block;
        margin: 0 2em 0 auto;
      }
    }
    .entity-details {
      i {
        margin-right: 0.3rem;
        color: $aon-gray-06;
      }
      .row {
        margin-bottom: 1.5rem;
        height: 20%;
      }
      span {
        color: $aon-gray-06;
      }

      h4 {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        margin-bottom: 0.5em;
        font-weight: 700;
        color: $white;
        display: flex;
        align-items: left;
        justify-content: left;
      }

      h2 {
        font-family: 'Roboto', sans-serif;
        display: inline-block;
        font-size: 16px;
        margin-bottom: 0;
        font-weight: 400;
        color: $white;
        display: flex;
        align-items: left;
        justify-content: left;
      }
    }

    .blue-smoke--text {
      color: $aon-gray-06;
    }
    .read-more {
      text-decoration: underline;
    }

    .companyMetaData {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 1.5rem !important;
    }

    .entity-loading {
      min-height: 310px;
    }

    .company-logo {
      cursor: pointer;
    }
    .ye {
      align-items: center;
      justify-content: center;
    }
  }

  .light-btn {
    color: white;
    border-color: $aon-teal-light;
    background-color: transparent !important;
    box-shadow: none;
    border: 1px solid $aon-teal-light;
    i {
      color: $aon-teal-light;
    }
  }
  .back-button.app-button.app-button--variation-ghost[data-v-e34d4d46] {
    text-decoration: none;
    font-size: 0.85em;
    min-height: 1.5em;
    padding: 0 5px;
    margin-left: -5px;
    margin-bottom: 10px;

    &:hover {
      background: rgba(205, 219, 222, 0.1);
    }
  }
  .cor-widget {
    height: 409px;
    transition: height 0.4s linear;
  }
  .cor-widget-collapsed {
    height: 78px !important;
    transition: height 0.4s linear;
  }
}
</style>
